import { AppState } from '@hum/icm-app/src/state';
import { Action } from '@hum/icm-app/src/actions';
import produce from 'immer';
import { CompanyDetailActionType } from '../actions';

export const companyDetailReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case CompanyDetailActionType.COMPANY_FINANCIAL_SUMMARY_COMPONENT_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.showCompanyFinancialSummary = action.payload.status;
      });
    }
    case CompanyDetailActionType.COMPANY_QUICKBOOKS_RAW_DATA_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.showQuickBooksRawData = action.payload.status;
      });
    }
    case CompanyDetailActionType.IS_DOWNLOADING_COMPANY_FINANCIALS_FILE: {
      return produce(state, (newState) => {
        newState.isDownloadingCompanyFinancialsFile = {
          docType: action.payload.docType,
          status: true,
        };
      });
    }
    case CompanyDetailActionType.SUCCESSFULLY_DOWNLOADED_COMPANY_FINANCIALS: {
      return produce(state, (newState) => {
        newState.isDownloadingCompanyFinancialsFile = {
          docType: action.payload.docType,
          status: false,
        };
      });
    }
    case CompanyDetailActionType.FAILED_DOWNLOADED_COMPANY_FINANCIALS: {
      return produce(state, (newState) => {
        newState.isDownloadingCompanyFinancialsFile = {
          docType: action.payload.docType,
          status: false,
        };
      });
    }
    case CompanyDetailActionType.GET_COMPANY_TEASER_DATA_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.teaserData = action.payload.result;
      });
    }
  }
  return state;
};
