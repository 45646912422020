import { useFinancialSummaryVisiblity } from '@hum/icm-app/src/hooks/useFinancialSummaryVisibility';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@hum/icm-app/src/state';
import {
  apiInvestorDownloadCompanyPublicFinancesButtonClicked,
  downloadCompanyFinancialButtonClicked,
} from '../ducks/actions';
import { CompanyFileDocumentType } from '@hum/types';

export const useCompanyTabsView = (
  companyId: number,
  targetCompanyId: number
) => {
  const dispatch = useDispatch();
  const clickDownloadButton = () => {
    dispatch(
      apiInvestorDownloadCompanyPublicFinancesButtonClicked({
        companyId: companyId,
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
        companyCreatorName: 'Hum Capital',
      })
    );
    dispatch(
      downloadCompanyFinancialButtonClicked({
        companyId: targetCompanyId,
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
      })
    );
  };
  const { isDownloadingCompanyFinancialsFile } = useSelector(
    (state: AppState) => state
  );
  const showCompanyFinancialSummary = useFinancialSummaryVisiblity(
    targetCompanyId
  );

  return {
    showCompanyFinancialSummary,
    isDownloadingCompanyFinancialsFile,
    clickDownloadButton,
  };
};
