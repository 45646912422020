import React, { useState } from 'react';

import * as styles from './pass.pc';
import { Scrollable, Spinner } from '@hum/ui-library';

import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

import { getUserCompanyId, Routes } from '@hum/icm-app/src/state';
import { useHistory } from 'react-router';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { PageContent } from '@hum/legacy-ui';
import { useAPI } from '../../hooks/useAPI';
import { useAnalyticsEvent } from '@hum/api';

enum RejectReason {
  weHaveAlreadyMet = 'We’ve already met',
  metricsAreNotStrongEnough = "Metrics aren't strong enough",
  notInMyCreditBox = 'Not in my credit box',
}

export const MatchFeedbackPage = () => {
  const { state } = useAppStore();

  const companyId = getUserCompanyId(state);
  const uuid = state.history?.query.uuid;
  let content;

  if (!companyId || !uuid) {
    content = <Spinner fullScreen />;
  } else {
    content = <MatchFeedback companyId={companyId} uuid={uuid} />;
  }

  return (
    <>
      <Topbar />
      <Scrollable>
        <PageContent center fixedWidth padded>
          {content}
        </PageContent>
      </Scrollable>
    </>
  );
};

const MatchFeedback = ({
  companyId,
  uuid,
}: {
  companyId: number;
  uuid: string;
}) => {
  const history = useHistory();
  const { dispatch } = useAppStore();
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [matchFeedbackSent, setMatchFeedbackSent] = useState(false);
  const api = useAPI();

  const trackEvent = useAnalyticsEvent();

  const sendRejectReason = async (reason: RejectReason) => {
    setSendingFeedback(true);
    trackEvent(
      'investor:syndication:match:rejected:reasons',
      {
        reason,
      },
      false
    );
    await api.syndication.sendRejectReasons(dispatch)(companyId, uuid, reason);

    setSendingFeedback(false);
    setMatchFeedbackSent(true);
  };

  if (matchFeedbackSent) {
    return (
      <styles.Thanks
        onKeepBrowsingCompanies={() => {
          history.push(Routes.SYNDICATION_INVESTOR_MATCHES);
        }}
      />
    );
  }

  return (
    <styles.PassForm
      onWeveAlreadyMet={() => {
        sendRejectReason(RejectReason.weHaveAlreadyMet);
      }}
      onMetricsArentStrongEnough={() => {
        sendRejectReason(RejectReason.metricsAreNotStrongEnough);
      }}
      onNotInMyCreditBox={() => {
        sendRejectReason(RejectReason.notInMyCreditBox);
      }}
      disabled={sendingFeedback}
      onReturnMatch={() => {
        history.push(`${Routes.SYNDICATION_INVESTOR_MATCH}?uuid=${uuid}`);
      }}
    />
  );
};
