import React from 'react';
import { useSyndicationConditionsNeedingAcceptance } from '@hum/icm-app/src/hooks/syndication';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { CompanyType } from '@hum/types';
import LegalAgreementsModal from './LegalAgreementsModal';

export const LegalAgreements = () => {
  const { state } = useAppStore();
  const currentUser = state.session.data;
  const currentCompany = state.session.data?.company;
  const companyRoles = (currentUser!.roles as unknown) as CompanyType[];
  const conditionsNeedingAcceptance = useSyndicationConditionsNeedingAcceptance(
    currentCompany!.id,
    companyRoles
  );

  const doesInvestorNeedsToAcceptAgreements = Boolean(
    currentCompany &&
      conditionsNeedingAcceptance.loaded &&
      conditionsNeedingAcceptance.data?.tos
  );

  return (
    <LegalAgreementsModal
      isOpen={doesInvestorNeedsToAcceptAgreements}
      companyId={currentCompany!.id}
      syndicationTosVersion={conditionsNeedingAcceptance.data?.tos}
    />
  );
};
