import React from "react";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import "./base.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_8e36d6da _7ab5bb97 _pub-7ab5bb97" + " " + "_7ab5bb97_v3 _pub-7ab5bb97_v3 v3 " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["height100"] ? " " + "_7ab5bb97_height100 _pub-7ab5bb97_height100 height100" : "") + (props["width100"] ? " " + "_7ab5bb97_width100 _pub-7ab5bb97_width100 width100" : "") + (props["noScrollX"] ? " " + "_7ab5bb97_noScrollX _pub-7ab5bb97_noScrollX noScrollX" : ""),
    "ref": ref,
    "data-pc-label": "Container",
  }, 
    props["children"]
  )
}));
export { Container };

var CenterCard = React.memo(React.forwardRef(function CenterCard(props, ref) {
  return React.createElement("div", {
    "className": "_675573ef _7ab5bb97 _pub-7ab5bb97" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CenterCard };

var Shhhh = React.memo(React.forwardRef(function Shhhh(props, ref) {
  return React.createElement("div", {
    "className": "_895b12c3 _7ab5bb97 _pub-7ab5bb97" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    "\n  Name to be revealed after intro\n"
    
  )
}));
export { Shhhh };

var Detail = React.memo(React.forwardRef(function Detail(props, ref) {
  return React.createElement("div", {
    "className": "_6ee33fc4 _7ab5bb97 _pub-7ab5bb97" + (props["light"] ? " " + "_7ab5bb97_light _pub-7ab5bb97_light light" : "") + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_29dd19be _7ab5bb97 _pub-7ab5bb97",
    }, 
      props["label"]
    ),
    React.createElement("div", {
      "className": "_b0d44804 _7ab5bb97 _pub-7ab5bb97",
    }, 
      props["value"]
    )
  )
}));
export { Detail };

var DetailHorizontal = React.memo(React.forwardRef(function DetailHorizontal(props, ref) {
  return React.createElement("div", {
    "className": "_d7628029 _7ab5bb97 _pub-7ab5bb97" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_99d38383 _7ab5bb97 _pub-7ab5bb97",
    }, 
      props["label"]
    ),
    React.createElement("div", {
      "className": "_dad239 _7ab5bb97 _pub-7ab5bb97",
    }, 
      props["value"]
    )
  )
}));
export { DetailHorizontal };


