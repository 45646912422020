import React from "react";
import _pub8Ac8C6B9, {Preview as _pub8Ac8C6B9_Preview} from "../../components/Topbar/index.pc";
import _pub7Ab5Bb97, {CenterCard as _pub7Ab5Bb97_CenterCard,Container as _pub7Ab5Bb97_Container} from "./base.pc";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import "./pass.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Title = React.memo(React.forwardRef(function Title(props, ref) {
  return React.createElement("div", {
    "className": "_61de243e _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Title };

var Thanks = React.memo(React.forwardRef(function Thanks(props, ref) {
  return React.createElement(_pub7Ab5Bb97_CenterCard, {
    "class": "_86660939 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Title, {
      "class": "_4775fb52",
    }, 
      "Thank you"
      
    ),
    React.createElement("div", {
      "className": "_a97b9a7e _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    }, 
      "\n    Your feedback will help us get you better matches next time\n  "
      
    ),
    React.createElement("div", {
      "className": "_371f0fdd _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_9404d9f6 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
        "v3": true,
        "primary": true,
        "wide": true,
        "onClick": props["onKeepBrowsingCompanies"],
      }, 
        "\n      Keep browsing companies\n    "
        
      )
    )
  )
}));
export { Thanks };

var PassForm = React.memo(React.forwardRef(function PassForm(props, ref) {
  return React.createElement(_pub7Ab5Bb97_CenterCard, {
    "class": "_7a9a8a69 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_fe78b8f6 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    }, 
      React.createElement(Title, {
        "class": "_b771cbd7",
      }, 
        "We’re sorry it’s a pass"
        
      ),
      React.createElement("div", {
        "className": "_597faafb _ff863e33 _pub-ff863e33 _pub-a97d53f3",
      }, 
        "\n      Could you tell us why?\n    "
        
      )
    ),
    React.createElement("div", {
      "className": "_1076d9da _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_b4f51fb9 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
        "v3": true,
        "wide": true,
        "onClick": props["onWeveAlreadyMet"],
        "disabled": props["disabled"],
      }, 
        "\n      We’ve already met\n    "
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_2dfc4e03 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
        "v3": true,
        "wide": true,
        "onClick": props["onMetricsArentStrongEnough"],
        "disabled": props["disabled"],
      }, 
        "\n      Metrics aren't strong enough\n    "
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_5afb7e95 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
        "v3": true,
        "wide": true,
        "onClick": props["onNotInMyCreditBox"],
        "disabled": props["disabled"],
      }, 
        "\n      Not in my credit box\n    "
        
      ),
      React.createElement("div", {
        "className": "_c49feb36 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
      }, 
        React.createElement("a", {
          "className": "_8ee92630 _ff863e33 _pub-ff863e33 _pub-a97d53f3" + (props["hover"] ? " " + "_ff863e33_hover _pub-ff863e33_hover _pub-a97d53f3_hover hover" : ""),
          "onClick": props["onReturnMatch"],
        }, 
          "\n        My mistake, I actually want the introduction!\n      "
          
        )
      )
    )
  )
}));
export { PassForm };

var FeedbackPreview = React.memo(React.forwardRef(function FeedbackPreview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_9494eb45 _ff863e33 _pub-ff863e33 _pub-a97d53f3" + (props["humDark"] ? " " + "_ff863e33_hum _pub-ff863e33_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_ff863e33_dark _pub-ff863e33_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9_Preview, {
      "class": "_fdfc6c98 _ff863e33 _pub-ff863e33 _pub-a97d53f3",
      "investor": true,
    }, null),
    React.createElement(PassForm, {
      "class": "_8afb5c0e",
      "hover": props["hover"],
    }, null)
  )
}));
export { FeedbackPreview };

var ThanksPreview = React.memo(React.forwardRef(function ThanksPreview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_7441025b _ff863e33 _pub-ff863e33 _pub-a97d53f3" + (props["humDark"] ? " " + "_ff863e33_hum _pub-ff863e33_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_ff863e33_dark _pub-ff863e33_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9_Preview, {
      "class": "_f06be94e _ff863e33 _pub-ff863e33 _pub-a97d53f3",
    }, null),
    React.createElement(Thanks, {
      "class": "_876cd9d8",
    }, null)
  )
}));
export { ThanksPreview };


