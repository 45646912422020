import { Action, HistoryAction } from '../actions';
import { AppState } from '../state';
import { appReducer } from './app';
import { historyReducer } from './history';

/**
 * reduces abstract state that's contains or is outside
 * of expicit app code (like HTTP requests).
 *
 * !! Chances are you want to add functionality in ./app.ts
 */

export function rootReducer(state, action: Action): AppState {
  state = appReducer(state, action);
  state = historyReducer(state, (action as any) as HistoryAction);
  return state;
}
