import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import ReactMarkdown from 'react-markdown';
import { Routes } from '@hum/icm-app/src/state/routes';
import { UpdateFundingProfile } from '@hum/icm-app/src/pages/settings/funding-profile/UpdateFundingProfile';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { getUserCompanyId, NoticeName } from '@hum/icm-app/src/state';
import { useCompany } from '@hum/icm-app/src/hooks/useCompany';
import { money } from '@hum/icm-app/src/utils/format';
import { useCompanyNotice } from '@hum/icm-app/src/hooks/useCompanyNotice';
import { Text, TextType } from '@hum/ui-library/src/atoms/Text';
import {
  Button,
  ButtonSize,
  ButtonType,
  DescriptionList,
  Icon,
  Spinner,
  Topbar,
} from '@hum/ui-library';
import { useAnalyticsEvent } from '@hum/api';

export const VerifyInformation = () => {
  const { state } = useAppStore();
  const history = useHistory();
  const trackEvent = useAnalyticsEvent();
  const syndicationInvestorCompanyId = getUserCompanyId(state);
  const [
    verifyInformationCompleted,
    verifyInformationCompletedLoaded,
    setVerifyInformationCompleted,
  ] = useCompanyNotice(
    NoticeName.SyndicationInvestorSetPassword,
    syndicationInvestorCompanyId
  );
  const companyResult = useCompany(syndicationInvestorCompanyId);
  const [updateModal, setUpdateModal] = useState(false);
  const openEditCompanyModal = () => {
    setUpdateModal(true);
  };
  const onCloseCompanySyndicationModal = () => {
    setUpdateModal(false);
  };
  useEffect(() => {
    if (verifyInformationCompleted && verifyInformationCompletedLoaded) {
      history.push(Routes.SYNDICATION);
    }
  }, [verifyInformationCompleted]);

  if (!companyResult.data?.syndicationInvestor) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <Topbar
        background="bg-defaultBg"
        logo={<Icon.Hum />}
        title="|  Investor"
        userLinks={
          <a href="mailto: hello@humcapital.com" className="ui-link">
            Contact us
          </a>
        }
      />

      <div className="container mx-auto p-4">
        <div className="max-w-[512px] mx-auto pt-14 pb-12">
          <div className="flex flex-start my-6">
            <div className="h-0.5 w-4 bg-[#3E4758] rounded" />
            <div className="h-0.5 w-10 bg-blue-500 rounded ml-2" />
          </div>
          <div className="mb-10">
            <Text type={TextType.DISPLAY_S}>Verify your information</Text>
            <Text type={TextType.UI_ELEMENT} className="mt-2" subdued>
              We have captured your investment criteria to maximize your deal
              flow. Take a look!
            </Text>
          </div>
          <div className="my-2">
            <Text type={TextType.HEADING_M}>
              {`About ${
                companyResult.data?.syndicationInvestor?.company?.name || ' '
              }`}
            </Text>
            <ReactMarkdown
              components={{
                p: ({ children }) => (
                  <Text type={TextType.BODY_M} subdued className="mt-1">
                    {children}
                  </Text>
                ),
              }}
              children={companyResult.data?.description || '–'}
            />
            <DescriptionList className="mt-2">
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Location
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M}>
                  {companyResult.data?.syndicationInvestor?.location || '–'}
                </Text>
              </dd>
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Website
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M} forceCapitalization={false}>
                  {companyResult.data?.website || '–'}
                </Text>
              </dd>
            </DescriptionList>
          </div>
          <div className="my-10">
            <Text type={TextType.HEADING_M}>Investment preferences</Text>
            <DescriptionList className="mt-2">
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Investment range
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M}>
                  {(companyResult.data?.syndicationInvestor?.investmentRanges
                    .length &&
                    companyResult.data?.syndicationInvestor?.investmentRanges.map(
                      ({ label }) => <dd key={label}> {label}</dd>
                    )) ||
                    '–'}
                </Text>
              </dd>
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Investment types
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M}>
                  {(companyResult.data?.syndicationInvestor?.investmentTypes
                    .length &&
                    companyResult.data?.syndicationInvestor?.investmentTypes.map(
                      ({ label }) => <dd key={label}> {label}</dd>
                    )) ||
                    '–'}
                </Text>
              </dd>
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Target yearly min. revenue
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M}>
                  {money(
                    companyResult.data?.syndicationInvestor
                      ?.investmentYearlyRevenueMin
                  ) || '–'}
                </Text>
              </dd>
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Target yearly max. revenue
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M}>
                  {money(
                    companyResult.data?.syndicationInvestor
                      ?.investmentYearlyRevenueMax
                  ) || '–'}
                </Text>
              </dd>
              <dt>
                <Text type={TextType.BODY_M} subdued>
                  Target business types
                </Text>
              </dt>
              <dd>
                <Text type={TextType.BODY_M}>
                  {(companyResult.data?.syndicationInvestor
                    ?.investmentBusinessTypes.length &&
                    companyResult.data?.syndicationInvestor?.investmentBusinessTypes.map(
                      ({ label }) => <dd>{label}</dd>
                    )) ||
                    '–'}
                </Text>
              </dd>
            </DescriptionList>
          </div>
          <div className="my-4 flex flex-col gap-4">
            <Button
              size={ButtonSize.LG}
              className="w-full md:w-[352px]"
              onClick={async () => {
                trackEvent(
                  'investor:syndication:signup:information_confirmation',
                  {},
                  false
                );
                await setVerifyInformationCompleted();
                history.push(`${Routes.SYNDICATION}`);
              }}
            >
              Confirm
            </Button>
            <Button
              type={ButtonType.SECONDARY}
              size={ButtonSize.LG}
              className="w-full md:w-[352px] bg-borderColor"
              onClick={openEditCompanyModal}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
      <UpdateFundingProfile
        isOpen={updateModal}
        onClose={onCloseCompanySyndicationModal}
        currentCompany={companyResult.data}
      />
    </>
  );
};
