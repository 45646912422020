import React from "react";
import _pubBab5A68F from "../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pubFe0274B0 from "../../../../design-system/src/Tooltip.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pub7Ab5Bb97, {Container as _pub7Ab5Bb97_Container,Detail as _pub7Ab5Bb97_Detail} from "./base.pc";
import _pubCb76A421, {Match as _pubCb76A421_Match} from "./matches.pc";
import _pub628B3C81 from "./interested.pc";
import _pubFf863E33 from "./pass.pc";
import _pubD7465D49, {Preview as _pubD7465D49_Preview} from "./company-detail.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import _pub8A05Fd1A from "../../../../design-system/src/BlankSlate.pc";
import _pub8D610C4 from "../../../../design-system/src/PageContent.pc";
import _pubFc2Ceb2, {TabNavigationContainer as _pubFc2Ceb2_TabNavigationContainer,TabNavigationItems as _pubFc2Ceb2_TabNavigationItems,TabNavigationItem as _pubFc2Ceb2_TabNavigationItem} from "../../../../design-system/src/TabNavigation.pc";
import _pubA8875Cb4, {BreadcrumbItem as _pubA8875Cb4_BreadcrumbItem} from "../../../../design-system/src/Breadcrumbs.pc";
import "./sidebar.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var BreadCrumb = React.memo(React.forwardRef(function BreadCrumb(props, ref) {
  return React.createElement("div", {
    "className": "_7643bf4f _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubA8875Cb4, {
      "class": "_1c12c3be _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      "padded": true,
    }, 
      React.createElement(_pubA8875Cb4_BreadcrumbItem, {
        "class": "_3f017492 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      }, 
        props["sectionName"]
      ),
      React.createElement(_pubA8875Cb4_BreadcrumbItem, {
        "class": "_48064404 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      }, 
        props["name"]
      )
    )
  )
}));
export { BreadCrumb };

var Detail = React.memo(React.forwardRef(function Detail(props, ref) {
  return React.createElement("div", {
    "className": "_91fb9248 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
    "data-testid": "title-tooltip",
  }, 
    React.createElement("TooltipContainer", {
      "className": "_168ceeb4 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    }, 
      React.createElement(_pubFe0274B0, {
        "class": "_8031caf3 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      }, 
        props["tooltip"]&&React.createElement("div", {
          "className": "_e102f809 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        }, 
          props["tooltip"]
        )
      ),
      React.createElement("div", {
        "className": "_f736fa65 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      }, 
        props["title"]
      )
    ),
    props["description"]&&React.createElement("div", {
      "className": "_13ec7799 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3" + " " + "_ba0551b0_text-body-medium _pub-ba0551b0_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    }, 
      props["description"]
    )
  )
}));
export { Detail };

var PageContent = React.memo(React.forwardRef(function PageContent(props, ref) {
  return React.createElement(_pub8D610C4, {
    "class": "_b40d49b9 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3" + (props["fixedWidth"] ? " " + "_ba0551b0_fixed-width _pub-ba0551b0_fixed-width _pub-a97d53f3_fixed-width fixed-width" : "") + (props["padded"] ? " " + "_ba0551b0_padded _pub-ba0551b0_padded _pub-a97d53f3_padded padded" : ""),
    "ref": ref,
    "testId": props["testId"],
    "bottomSpace": props["bottomSpace"],
    "padded": props["padded"],
    "fixedWidth": props["fixedWidth"],
    "center": props["center"],
  }, 
    React.createElement("div", {
      "className": "_7d2d2074 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    }, 
      props["breadCrumbs"]
    ),
    React.createElement(Detail, {
      "class": "_a2a10e2",
      "title": props["title"],
      "tooltip": props["tooltip"],
      "description": props["description"],
    }, null),
    props["children"]
  )
}));
export { PageContent };

var NavContent = React.memo(React.forwardRef(function NavContent(props, ref) {
  return React.createElement("div", {
    "className": "_5a032895 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubFc2Ceb2_TabNavigationContainer, {
      "class": "_9aec48c _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    }, 
      React.createElement(_pubFc2Ceb2_TabNavigationItems, {
        "class": "_4c15a0fc _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      }, 
        props["items"]
      ),
      props["children"]
    )
  )
}));
export { NavContent };

var NavContentCompany = React.memo(React.forwardRef(function NavContentCompany(props, ref) {
  return React.createElement("div", {
    "className": "_b3608da0 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubFc2Ceb2_TabNavigationContainer, {
      "class": "_d23b83e _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    }, 
      React.createElement(_pubFc2Ceb2_TabNavigationItems, {
        "class": "_c355555c _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      }, 
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_4a250cca _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
          "tagName": "div",
          "inactive": true,
        }, 
          React.createElement("img", {
            "className": "_88e71e0 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "src": getDefault(require("@hum/figma/spot-icons/symbols/home-building@1.svg")),
          }, null),
          React.createElement("div", {
            "className": "_9187205a _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
          }, 
            props["title"]
          )
        ),
        props["items"]
      ),
      props["children"]
    )
  )
}));
export { NavContentCompany };

var EmptyNewDeals = React.memo(React.forwardRef(function EmptyNewDeals(props, ref) {
  return React.createElement("div", {
    "className": "_5d6eec8c _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8A05Fd1A, {
      "class": "_ea76c50 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      "illustration": React.createElement("img", {
        "className": "_9fbe29b9 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "src": getDefault(require("./assets/globe.png")),
      }, null),
      "title": "Nothing new here",
      "description": "We're looking for more companies that match your criteria",
      "controls": React.createElement(_pub2Dbb4493, {
        "class": "_6b77803 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "v3": true,
        "primary": true,
        "onClick": props["onClick"],
      }, 
        "Open Live Deals"
        
      ),
    }, null)
  )
}));
export { EmptyNewDeals };

var EmptyLiveDeals = React.memo(React.forwardRef(function EmptyLiveDeals(props, ref) {
  return React.createElement("div", {
    "className": "_bad6c18b _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8A05Fd1A, {
      "class": "_439415a _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      "illustration": React.createElement("img", {
        "className": "_7f615e8a _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "src": getDefault(require("./assets/molecules.png")),
      }, null),
      "title": "No Live Deals yet",
      "description": "Accept a New deal to start tracking Live deals",
      "controls": React.createElement(_pub2Dbb4493, {
        "class": "_e6680f30 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "v3": true,
        "primary": true,
        "onClick": props["onClick"],
      }, 
        "Open New Deals"
        
      ),
    }, null)
  )
}));
export { EmptyLiveDeals };

var EmptyArchiveDeals = React.memo(React.forwardRef(function EmptyArchiveDeals(props, ref) {
  return React.createElement("div", {
    "className": "_ad1678f8 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(components, {
      "class": "_b2967787 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      "illustration": React.createElement("img", {
        "className": "_87b55fd5 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "src": getDefault(require("./empty-archive.svg")),
      }, null),
      "title": "Nothing here yet",
      "description": "Come here to see deals you passed on",
    }, null)
  )
}));
export { EmptyArchiveDeals };

var EmptyDataRoom = React.memo(React.forwardRef(function EmptyDataRoom(props, ref) {
  return React.createElement("div", {
    "className": "_431819d4 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8A05Fd1A, {
      "class": "_b112a3e9 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      "illustration": React.createElement("img", {
        "className": "_2dbc975e _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "src": getDefault(require("./assets/laptop.png")),
      }, null),
      "title": "Nothing here yet",
      "description": "We’ll notify you when the company uploads new information",
    }, null)
  )
}));
export { EmptyDataRoom };

var EmptyInsights = React.memo(React.forwardRef(function EmptyInsights(props, ref) {
  return React.createElement("div", {
    "className": "_aa7bbce1 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8A05Fd1A, {
      "class": "_b59fdf5b _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
      "illustration": React.createElement("img", {
        "className": "_8d7c882 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "src": getDefault(require("./assets/barchart.png")),
      }, null),
      "title": "Nothing here yet",
      "description": "We’ll notify you when there is new information available",
    }, null)
  )
}));
export { EmptyInsights };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_4475ddcd _ba0551b0 _pub-ba0551b0 _pub-a97d53f3" + " " + "_ba0551b0_hum _pub-ba0551b0_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_ba0551b0_dark _pub-ba0551b0_dark _pub-a97d53f3_dark dark",
    "ref": ref,
  }, 
    React.createElement(NavContentCompany, {
      "class": "_c11c3ba3",
      "title": "Dino 32",
      "items": React.createElement(React.Fragment, {
      }, 
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_2f966c79 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
          "active": true,
        }, 
          "\n        New deals\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_58915cef _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        }, 
          "\n        Live deals\n      "
          
        ),
        React.createElement(_pubFc2Ceb2_TabNavigationItem, {
          "class": "_c1980d55 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        }, 
          "\n        Archive deals\n      "
          
        )
      ),
    }, 
      props["children"]
    )
  )
}));
export { Preview };

var NewDealListPreview = React.memo(React.forwardRef(function NewDealListPreview(props, ref) {
  return React.createElement(Preview, {
    "class": "_a3cdf0ca",
    "ref": ref,
    "humDark": props["humDark"],
  }, 
    React.createElement(PageContent, {
      "class": "_cb8216a9",
      "fixedWidth": true,
      "center": true,
      "padded": true,
      "title": "New Deals",
    }, 
      React.createElement(_pubCb76A421_Match, {
        "class": "_cc3b01cf _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "title": "Company 1",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. ",
        "hover": props["hoverItem"],
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_ed7ce14c _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Location",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_9a7bd1da _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Industry Vertical",
            "value": "Puters 👾",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_3728060 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_7475b0f6 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Equity Raised",
            "value": "&#36;1 bn",
          }, null)
        ),
      }, null),
      React.createElement(_pubCb76A421_Match, {
        "class": "_bb3c3159 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "title": "Company 2",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. ",
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_5402ec1e _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Location",
            "value": "San Francisco, CA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_2305dc88 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Industry Vertical",
            "value": "Clothing",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_ba0c8d32 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_cd0bbda4 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Equity Raised",
            "value": "&#36;1 tn",
          }, null)
        ),
      }, null),
      React.createElement(_pubCb76A421_Match, {
        "class": "_223560e3 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
        "title": "Company 3",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_46b743f0 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Location",
            "value": "Taipei, Taiwan",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_31b07366 _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Industry Vertical",
            "value": "Hamburgers 🍔",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_a8b922dc _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_dfbe124a _ba0551b0 _pub-ba0551b0 _pub-a97d53f3",
            "label": "Equity Raised",
            "value": "&#36;12 m",
          }, null)
        ),
      }, null)
    )
  )
}));


