import React, { useState, useEffect } from 'react';

import { Detail, SelectCard, TextInput, CheckboxField } from '@hum/legacy-ui';
import { Modal } from '@hum/design-system';
import { Button, ButtonType } from '@hum/ui-library';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  apiSentDealToArchive,
  apiCompanyIsFundraising,
} from '@hum/icm-app/src/actions';
import {
  getUserCompanyId,
  MatchOutReasonsPayloadType,
  SyndicationMatchOutReasons,
} from '@hum/icm-app/src/state';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useSyndicationMatchOutReasons } from '@hum/icm-app/src/hooks/useSyndicationMatchOutReasons';
import { toast } from '@hum/common/src/modules/toast';

export type MatchPassModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userType: string;
  title: string;
  subtitle?: string;
};

const initialValues = { notifyFundraising: false };

export const MatchPassModal = ({
  isOpen,
  onClose,
  userType,
  title,
  subtitle,
}: MatchPassModalProps) => {
  const { state, dispatch } = useAppStore();
  const companyInvestorId = getUserCompanyId(state);
  const [otherDescription, setOtherDescription] = useState('');
  const matchUuid = state.history?.query.uuid;
  const [otherInputState, setOtherInputState] = useState(false);
  const reasons = useSyndicationMatchOutReasons();
  const [reasonsList, setReasonList] = useState<SyndicationMatchOutReasons[]>(
    reasons.data?.map((item) => ({
      ...item,
      checked: false,
    })) as SyndicationMatchOutReasons[]
  );
  const form = useForm({
    initialValues,
    onSubmit: async (fields) => {
      const payload = {
        companyId: companyInvestorId,
        matchUuid: matchUuid,
        isFundraising: fields.notifyFundraising,
      };
      if (fields.notifyFundraising) {
        dispatch(apiCompanyIsFundraising(payload));
      }
    },
  });
  const notifyFundraising = useField('notifyFundraising', 'checkbox', form)
    .input;
  const sendDealToArchive = async (data: SyndicationMatchOutReasons[]) => {
    const payload = data
      .filter((item) => item.checked === true)
      .map((item) => item.id);

    const formedRequest: MatchOutReasonsPayloadType = {
      passReasonIds: payload,
      otherDescription: otherDescription,
    };
    if (payload.length > 0) {
      form.handleSubmit();
      dispatch(
        apiSentDealToArchive({
          companyId: companyInvestorId,
          matchUuid: matchUuid,
          userType: userType,
          reasons: formedRequest,
        })
      );
    } else {
      toast.error('You need to choose at least one reason.');
    }
  };
  const handleClose = () => {
    onClose();
  };

  const checkReason = (index: number) => {
    if (reasonsList && reasonsList[index].reason === 'Other') {
      setOtherInputState(!otherInputState);
    }
    const newList = [...(reasonsList as SyndicationMatchOutReasons[])];
    newList[index].checked = !newList[index].checked;
    setReasonList(newList);
  };
  useEffect(() => {
    setReasonList(
      reasons.data?.map((item) => ({
        ...item,
        checked: false,
      })) as SyndicationMatchOutReasons[]
    );
  }, [reasons]);
  return (
    <Modal
      visible={isOpen}
      header="Archive company"
      onClose={handleClose}
      side
      wide
      scrollable
      footer={
        <>
          <Button type={ButtonType.SECONDARY} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            testId="send-feedback-button-archive"
            onClick={async () => {
              reasonsList && sendDealToArchive(reasonsList);
            }}
            disabled={
              reasonsList &&
              reasonsList.filter((item) => item.checked === true).length > 0
                ? false
                : true
            }
          >
            Send Feedback
          </Button>
        </>
      }
    >
      <Detail title={title} />
      <Detail medium padded title="Monitoring preferences" />
      <SelectCard
        form={form}
        checkbox={notifyFundraising}
        checkboxId="notifyFundraising"
        checkboxIsToggle
        description={<p>Notify me next time this company is fundraising</p>}
      />
      <Detail subtitle padded title={subtitle} />
      {reasonsList &&
        reasonsList.map((item, index) => (
          <CheckboxField
            key={`match-out-${item?.reason}`}
            data-testid={`reason-match-out-${index}-input`}
            label={
              item.reason === 'Other' ? (
                <TextInput
                  placeholder="Other"
                  onValueChange={setOtherDescription}
                  data-testid="input-math-out-other-reason"
                  value={otherDescription}
                  disabled={
                    item.reason === 'Other' && otherInputState ? false : true
                  }
                  autoFocus={otherInputState}
                  minimal
                />
              ) : (
                item.reason
              )
            }
            value={
              item.reason === 'Other' ? !!otherDescription.length : item.checked
            }
            spaceBottom
            onValueChange={() => {
              checkReason(index);
            }}
          />
        ))}
    </Modal>
  );
};
