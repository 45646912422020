import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';
import { BaseRequestStateChanged } from '@hum/common/src/ducks/actions';
import { CompanyFileDocumentType, CompanyPublicProfileData } from '@hum/types';
import {
  actionCreators,
  ExtractJoinedActionFromCreators,
  identity,
} from '@hum/common';

export enum CompanyDetailActionType {
  DOWNLOAD_COMPANY_FINANCIAL_BUTTON_CLICKED = 'DOWNLOAD_COMPANY_FINANCIAL_BUTTON_CLICKED',
  DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED = 'DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED',
  DOWNLOAD_MATCH_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED = 'DOWNLOAD_MATCH_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED',
  FAILED_DOWNLOADED_COMPANY_FINANCIALS = 'FAILED_DOWNLOADED_COMPANY_FINANCIALS',
  SUCCESSFULLY_DOWNLOADED_COMPANY_FINANCIALS = 'SUCCESSFULLY_DOWNLOADED_COMPANY_FINANCIALS',
  IS_DOWNLOADING_COMPANY_FINANCIALS_FILE = 'IS_DOWNLOADING_COMPANY_FINANCIALS_FILE',
  COMPANY_FINANCIAL_SUMMARY_COMPONENT_SHOWED = 'COMPANY_FINANCIAL_SUMMARY_COMPONENT_SHOWED',
  COMPANY_FINANCIAL_SUMMARY_COMPONENT_STATE_CHANGED = 'COMPANY_FINANCIAL_SUMMARY_COMPONENT_STATE_CHANGED',
  COMPANY_QUICKBOOKS_RAW_DATA_COMPONENT_SHOWED = 'COMPANY_QUICKBOOKS_RAW_DATA_COMPONENT_SHOWED',
  COMPANY_QUICKBOOKS_RAW_DATA_STATE_CHANGED = 'COMPANY_QUICKBOOKS_RAW_DATA_STATE_CHANGED',
  USER_REVIEW_SESSION_REQUEST_SENT = 'USER_REVIEW_SESSION_REQUEST_SENT',
  GET_COMPANY_TEASER_DATA_REQUEST_SENT = 'GET_COMPANY_TEASER_DATA_REQUEST_SENT',
  GET_COMPANY_TEASER_DATA_REQUEST_STATE_CHANGED = 'GET_COMPANY_TEASER_DATA_REQUEST_SENT_CHANGED',

  // tracking events
  INVESTOR_DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED = 'INVESTOR_DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED',
  INVESTOR_DOWNLOAD_DATAROOM_FILE_BUTTON_CLICKED = 'INVESTOR_DOWNLOAD_DATAROOM_FILE_BUTTON_CLICKED',
}

export type DownloadCompanyFinancialButtonClicked = BaseAction<
  CompanyDetailActionType.DOWNLOAD_COMPANY_FINANCIAL_BUTTON_CLICKED,
  { companyId: number; docType: string; companyCreatorName?: string }
>;
export type DownloadCompanyPublicFinancesButtonClicked = BaseAction<
  CompanyDetailActionType.DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED,
  { companyUuid: string }
>;
export type DownloadMatchCompanyPublicFinancesButtonClicked = BaseAction<
  CompanyDetailActionType.DOWNLOAD_MATCH_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED,
  { companyId: number; matchUuid: string }
>;
export type FailedDownloadedCompanyFinancials = BaseAction<
  CompanyDetailActionType.FAILED_DOWNLOADED_COMPANY_FINANCIALS,
  { docType: CompanyFileDocumentType }
>;

export type IsDownloadingCompanyFinancialsFile = BaseAction<
  CompanyDetailActionType.IS_DOWNLOADING_COMPANY_FINANCIALS_FILE,
  {
    docType: CompanyFileDocumentType;
  }
>;

export type SuccessfullyDownloadedCompanyFinancials = BaseAction<
  CompanyDetailActionType.SUCCESSFULLY_DOWNLOADED_COMPANY_FINANCIALS,
  {
    docType: CompanyFileDocumentType;
  }
>;
export type CompanyFinancialComponentStateChanged = BaseAction<
  CompanyDetailActionType.COMPANY_FINANCIAL_SUMMARY_COMPONENT_STATE_CHANGED,
  { status: boolean }
>;
export type CompanyFinancialComponentShowed = BaseAction<
  CompanyDetailActionType.COMPANY_FINANCIAL_SUMMARY_COMPONENT_SHOWED,
  { companyId: number }
>;
export type CompanyQuickbooksRawDataStateChanged = BaseAction<
  CompanyDetailActionType.COMPANY_QUICKBOOKS_RAW_DATA_STATE_CHANGED,
  { status: boolean }
>;
export type CompanyQuickbooksRawDataShowed = BaseAction<
  CompanyDetailActionType.COMPANY_QUICKBOOKS_RAW_DATA_COMPONENT_SHOWED,
  { companyId: number }
>;

export const downloadCompanyFinancialButtonClicked = actionCreator<DownloadCompanyFinancialButtonClicked>(
  CompanyDetailActionType.DOWNLOAD_COMPANY_FINANCIAL_BUTTON_CLICKED
);
export const downloadCompanyPublicFinancesButtonClicked = actionCreator<DownloadCompanyPublicFinancesButtonClicked>(
  CompanyDetailActionType.DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED
);
export const downloadMatchCompanyPublicFinancesButtonClicked = actionCreator<DownloadMatchCompanyPublicFinancesButtonClicked>(
  CompanyDetailActionType.DOWNLOAD_MATCH_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED
);
export const failedDownloadedCompanyFinancials = actionCreator<FailedDownloadedCompanyFinancials>(
  CompanyDetailActionType.FAILED_DOWNLOADED_COMPANY_FINANCIALS
);
export const isDownloadingCompanyFinancialsFile = actionCreator<IsDownloadingCompanyFinancialsFile>(
  CompanyDetailActionType.IS_DOWNLOADING_COMPANY_FINANCIALS_FILE
);
export const successfullyDownloadedCompanyFinancials = actionCreator<SuccessfullyDownloadedCompanyFinancials>(
  CompanyDetailActionType.SUCCESSFULLY_DOWNLOADED_COMPANY_FINANCIALS
);
export const companyFinancialSummaryVisibilityHookUsed = actionCreator<CompanyFinancialComponentShowed>(
  CompanyDetailActionType.COMPANY_FINANCIAL_SUMMARY_COMPONENT_SHOWED
);
export const companyFinancialComponentStateChanged = actionCreator<CompanyFinancialComponentStateChanged>(
  CompanyDetailActionType.COMPANY_FINANCIAL_SUMMARY_COMPONENT_STATE_CHANGED
);
export const companyQuickbooksRawDataVisibilityHookUsed = actionCreator<CompanyQuickbooksRawDataShowed>(
  CompanyDetailActionType.COMPANY_QUICKBOOKS_RAW_DATA_COMPONENT_SHOWED
);
export const companyQuickbooksRawDataStateChanged = actionCreator<CompanyQuickbooksRawDataStateChanged>(
  CompanyDetailActionType.COMPANY_QUICKBOOKS_RAW_DATA_STATE_CHANGED
);

export type ApiGetCompanyTeaserDataRequestSent = BaseAction<
  CompanyDetailActionType.GET_COMPANY_TEASER_DATA_REQUEST_SENT,
  {
    token: string;
  }
>;

export const apiGetCompanyTeaserDataRequestSent = actionCreator<ApiGetCompanyTeaserDataRequestSent>(
  CompanyDetailActionType.GET_COMPANY_TEASER_DATA_REQUEST_SENT
);

export type ApiGetCompanyTeaserDataRequestStateChanged = BaseRequestStateChanged<
  CompanyDetailActionType.GET_COMPANY_TEASER_DATA_REQUEST_STATE_CHANGED,
  CompanyPublicProfileData
>;

export const apiGetCompanyTeaserDataRequestStateChanged = actionCreator<ApiGetCompanyTeaserDataRequestStateChanged>(
  CompanyDetailActionType.GET_COMPANY_TEASER_DATA_REQUEST_STATE_CHANGED
);

export type ApiInvestorDownloadCompanyPublicFinancesButtonClicked = BaseAction<
  CompanyDetailActionType.INVESTOR_DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED,
  {
    companyId: number;
    docType: CompanyFileDocumentType;
    companyCreatorName: string;
  }
>;

export const apiInvestorDownloadCompanyPublicFinancesButtonClicked = actionCreator<ApiInvestorDownloadCompanyPublicFinancesButtonClicked>(
  CompanyDetailActionType.INVESTOR_DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED
);

export type ApiInvestorDownloadDataroomFileButtonClicked = BaseAction<
  CompanyDetailActionType.INVESTOR_DOWNLOAD_DATAROOM_FILE_BUTTON_CLICKED,
  {
    companyId: number;
    userId?: number;
    fileId: number;
    documentType?: string;
  }
>;

export const apiInvestorDownloadDataroomFileButtonClicked = actionCreator<ApiInvestorDownloadDataroomFileButtonClicked>(
  CompanyDetailActionType.INVESTOR_DOWNLOAD_DATAROOM_FILE_BUTTON_CLICKED
);

export const companyDetailActions = actionCreators(
  {
    companyFinancialSummaryVisibilityHookUsed: identity<{
      companyId: number;
    }>(),
    companyFinancialComponentShowed: identity<{ companyId: number }>(),
  },
  'companyDetailAction'
);

export type CompanyDetailActions =
  | DownloadCompanyFinancialButtonClicked
  | DownloadCompanyPublicFinancesButtonClicked
  | DownloadMatchCompanyPublicFinancesButtonClicked
  | FailedDownloadedCompanyFinancials
  | ExtractJoinedActionFromCreators<typeof companyDetailActions>
  | IsDownloadingCompanyFinancialsFile
  | SuccessfullyDownloadedCompanyFinancials
  | CompanyFinancialComponentShowed
  | CompanyFinancialComponentStateChanged
  | CompanyQuickbooksRawDataShowed
  | CompanyQuickbooksRawDataStateChanged
  | ApiGetCompanyTeaserDataRequestSent
  | ApiGetCompanyTeaserDataRequestStateChanged
  | ApiInvestorDownloadCompanyPublicFinancesButtonClicked
  | ApiInvestorDownloadDataroomFileButtonClicked;
