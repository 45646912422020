import React, { useEffect } from 'react';
import { Spinner } from '@hum/ui-library';

export const MatchesPage = () => {
  useEffect(() => {
    //eslint-disable-next-line
    window.location.assign(`${process.env.V2_BASE_URL}/`);
  }, []);

  return <Spinner fullScreen />;
};
