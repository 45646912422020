import React, { memo, useCallback } from 'react';
import dayjs from 'dayjs';
import { useDownloadFile } from '@hum/icm-app/src/__DEPRECATED__modules/files/hooks/useFileManager/download';
import { CompanyFile } from '@hum/types';
import { Icon, TextType, Text, ButtonType, Button } from '@hum/ui-library';

export const renderDate = (date: string) => dayjs(date).format('MMMM D, YYYY');
export const renderFileDate = (date: string) => dayjs(date).format('MM.D.YY');

export const File = memo(({ file }: { file: CompanyFile }) => {
  const { download: downloadFile } = useDownloadFile(file.companyId);

  const onDownloadClick = useCallback(async () => {
    await downloadFile(file.id);
  }, []);

  const fileIcons = {
    csv: <Icon.CsvFile className="mr-4" />,
    xlsx: <Icon.ExcelFile className="mr-4" />,
    pdf: <Icon.PdfIcon className="mr-4" />,
    default: <Icon.DefaultFile className="mr-4" />,
  };
  const fileIcon = fileIcons[file.ext] || fileIcons['default'];

  return (
    <div>
      <li className="flex items-center p-4 select-none hover:bg-gray-700">
        {fileIcon}
        <Text
          type={TextType.UI_ELEMENT}
          className="flex-1 flex-wrap truncate mr-3"
          forceCapitalization={false}
        >
          {file.name}
        </Text>
        <Text type={TextType.BODY_MICO} subdued className="mx-6">
          {renderDate(file.updatedAt)}
        </Text>
        <Button
          type={ButtonType.QUIET}
          onClick={onDownloadClick}
          testId="download-file"
        >
          <Icon.Download className="mr-2" />
        </Button>
      </li>
    </div>
  );
});

File.displayName = 'File';
