import React from "react";
import _pub8Ac8C6B9, {Preview as _pub8Ac8C6B9_Preview} from "../../components/Topbar/index.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pubE434Fb6A from "../../../../design-system/src/Card.pc";
import _pub7Ab5Bb97, {Shhhh as _pub7Ab5Bb97_Shhhh,Container as _pub7Ab5Bb97_Container,Detail as _pub7Ab5Bb97_Detail} from "./base.pc";
import "./matches.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var NoTitle = React.memo(React.forwardRef(function NoTitle(props, ref) {
  return React.createElement("div", {
    "className": "_5bdde3bb _cb76a421 _pub-cb76a421",
    "ref": ref,
  }, 
    React.createElement(_pub7Ab5Bb97_Shhhh, {
      "class": "_7918e7d0 _cb76a421 _pub-cb76a421",
    }, null)
  )
}));
export { NoTitle };

var Title = React.memo(React.forwardRef(function Title(props, ref) {
  return React.createElement("div", {
    "className": "_bc65cebc _cb76a421 _pub-cb76a421",
    "ref": ref,
  }, 
    props["title"]
  )
}));
export { Title };

var Match = React.memo(React.forwardRef(function Match(props, ref) {
  return React.createElement(_pubE434Fb6A, {
    "class": "_e772fe01 _cb76a421 _pub-cb76a421" + (props["bigtitle"] ? " " + "bigtitle" : ""),
    "ref": ref,
    "interactive": true,
    "onClick": props["onClick"],
    "testId": props["testId"],
  }, 
    React.createElement("div", {
      "className": "_ea3a10b8 _cb76a421 _pub-cb76a421",
    }, 
      React.createElement("div", {
        "className": "_18696c4 _cb76a421 _pub-cb76a421",
      }, 
        props["title"]
      ),
      props["description"]&&React.createElement("div", {
        "className": "_5ae53d9c _cb76a421 _pub-cb76a421",
      }, 
        props["description"]
      )
    ),
    React.createElement("div", {
      "className": "_359b58d _cb76a421 _pub-cb76a421",
    }, 
      props["details"]
    ),
    React.createElement("button", {
      "className": "_ed57d4a1 _cb76a421 _pub-cb76a421",
    }, null)
  )
}));
export { Match };

var MatchesTitle = React.memo(React.forwardRef(function MatchesTitle(props, ref) {
  return React.createElement("div", {
    "className": "_97c9f2d _cb76a421 _pub-cb76a421",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { MatchesTitle };

var Content = React.memo(React.forwardRef(function Content(props, ref) {
  return React.createElement("div", {
    "className": "_e01f3a18 _cb76a421 _pub-cb76a421",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Content };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_e115b34 _cb76a421 _pub-cb76a421" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9_Preview, {
      "class": "_b90d26 _cb76a421 _pub-cb76a421",
      "investor": true,
    }, null),
    React.createElement(Content, {
      "class": "_eeb76c0a",
    }, 
      React.createElement(MatchesTitle, {
        "class": "_8ec66364",
      }, 
        "\n      Here are some companies that would like to meet you\n    "
        
      ),
      React.createElement(Match, {
        "class": "_60c80248",
        "title": "Company 1",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. ",
        "hover": props["hoverItem"],
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_69f1c80 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Location",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_71982c16 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Industry Vertical",
            "value": "Puters 👾",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_e8917dac _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_9f964d3a _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Equity Raised",
            "value": "&#36;1 bn",
          }, null)
        ),
      }, null),
      React.createElement(Match, {
        "class": "_feac97eb",
        "title": "Company 2",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. ",
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_9a8a4e0e _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Location",
            "value": "San Francisco, CA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_ed8d7e98 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Industry Vertical",
            "value": "Clothing",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_74842f22 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_3831fb4 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Equity Raised",
            "value": "&#36;1 tn",
          }, null)
        ),
      }, null),
      React.createElement(Match, {
        "class": "_89aba77d",
        "title": "Company 2",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "details": React.createElement(React.Fragment, {
        }, 
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_2236296b _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Location",
            "value": "Taipei, Taiwan",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_553119fd _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Industry Vertical",
            "value": "Hamburgers 🍔",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_cc384847 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Revenue Model",
            "value": "New York, USA",
          }, null),
          React.createElement(_pub7Ab5Bb97_Detail, {
            "class": "_bb3f78d1 _cb76a421 _pub-cb76a421",
            "light": true,
            "label": "Equity Raised",
            "value": "&#36;12 m",
          }, null)
        ),
      }, null)
    )
  )
}));
export { Preview };


