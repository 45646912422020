import React from 'react';
import { CompanyDataRoomSection, CompanyFile } from '@hum/types';
import { File } from './File';
import { Icon, TextType, Text } from '@hum/ui-library';

type DownloadSectionProps = {
  section: CompanyDataRoomSection;
  files: CompanyFile[] | [];
};

export const DownloadSection = ({ section, files }: DownloadSectionProps) => {
  const filteredFiles = files.filter((file) =>
    section.documentTypes.includes(file.documentType)
  );

  // hide section if there are no files
  if (filteredFiles.length === 0) {
    return null;
  }

  return (
    <div className="mb-4">
      <div className="rounded-[8px] border border-borderColor pt-4">
        <div className="flex items-center px-4 pt-2 pb-4">
          <Icon.Folder className="mr-4 fill-[#3E4758]" />
          <Text type={TextType.BODY_M}>{section.label}</Text>
        </div>
        {filteredFiles.map((file) => (
          <File file={file} key={file.name} />
        ))}
      </div>
    </div>
  );
};
