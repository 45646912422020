import React from 'react';
import { Route, Switch } from 'react-router';

import { CompanyDetailPage } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/CompanyDetailPage';
import { MatchFeedbackPage } from '@hum/icm-app/src/pages/syndication-v2/MatchFeedbackPage';
import { MatchInterestedPage } from '@hum/icm-app/src/pages/syndication-v2/MatchInterestedPage';
import { MatchesPage } from '@hum/icm-app/src/pages/syndication-v2/Matches/MatchesPage';
import { Routes } from '@hum/icm-app/src/state';

export const SyndicationInvestorV2Page = () => {
  return (
    <div>
      <Switch>
        <Route path={Routes.SYNDICATION_INVESTOR_MATCH_INTERESTED}>
          <MatchInterestedPage />
        </Route>
        <Route path={Routes.SYNDICATION_INVESTOR_MATCH_FEEDBACK}>
          <MatchFeedbackPage />
        </Route>
        <Route path={Routes.SYNDICATION_INVESTOR_MATCH}>
          <CompanyDetailPage />
        </Route>
        <Route path={Routes.SYNDICATION_INVESTOR_MATCHES}>
          <MatchesPage />
        </Route>
        <Route path={Routes.SYNDICATION}>
          <MatchesPage />
        </Route>
      </Switch>
    </div>
  );
};
