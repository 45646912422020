import {
  AppState,
  BlockPermissionsPayloadType,
} from '@hum/icm-app/src/state/app';
import {
  Action,
  SyndicationAction,
  SyndicationActionType,
} from '@hum/icm-app/src/actions';

import produce from 'immer';

export const syndicationReducer = (
  appState: AppState,
  action: Action | SyndicationAction
) => {
  switch (action.type) {
    case SyndicationActionType.API_PRE_INTRO_CHARTS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationPreIntroCharts,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_INSIGHTS_CHARTS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationInsightsCharts,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_RECOMMENDATIONS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationRecommendations,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCHES_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.syndicationMatches, action.payload.result);
      });
    }
    case SyndicationActionType.API_SYNDICATION_ONBOARDING_MATCHES_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationOnboardingMatches,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCH_REQUEST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.syndicationMatch = action.payload.result;
      });
    }
    case SyndicationActionType.API_SYNDICATION_EXISTING_PROOF_FILES_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationExistingProofFiles,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCH_TOC_VERSION_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationMatchTocVersion,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.companyDetailsForInvestorInterest,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_SEND_MATCH_RESPONSE_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.matchResponse, action.payload.result);
      });
    }
    case SyndicationActionType.API_SYNDICATION_ONBOARDING_RESULTS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationOnboardingResults,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationConditionsNeedingAcceptance,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_LOAD_OUT_OF_FEE_FILES_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationMatchOutOfFeeFiles,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCH_DATAROOM_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.syndicationMatchDataRoom, action.payload.result);
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCH_EVENTS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.syndicationMatchEvents[action.payload.matchId] =
          action.payload.result;
      });
    }

    case SyndicationActionType.API_SYNDICATION_MATCH_OUT_REASONS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationMatchOutReasons,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCHES_FOR_GUEST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.syndicationMatchesForGuest,
          action.payload.result
        );
      });
    }
    case SyndicationActionType.SYNDICATION_MATCH_PROFILE_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.currentMatchProfile = action.payload.result;
      });
    }
    case SyndicationActionType.API_SYNDICATION_MATCH_PROFILE_ANALYTICS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.currentMatchProfileAnalytics = action.payload.result;
      });
    }
    case SyndicationActionType.PERMISSIONS_CHECK_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.syndicationPermissions = action.payload.result;
      });
    }
    case SyndicationActionType.PERMISSIONS_STATE_CHANGED: {
      const res = action.payload.result;
      const { data } = res;
      if (res.loaded && res.data) {
        const {
          enable: enabled,
          permissions_set_name: permissionsSet,
        } = data as BlockPermissionsPayloadType;
        return produce(appState, (newState) => {
          newState.syndicationPermissions[permissionsSet] = enabled;
        });
      }
    }
    case SyndicationActionType.PERMISSIONS_ADMIN_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.adminSyndicationPermissions = action.payload.result;
      });
    }
    case SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.syndicationInvestorDetails = action.payload.result;
      });
    }
    case SyndicationActionType.TRANSACTION_DOCUMENT_UPLOAD_RESPONSE: {
      return produce(appState, (newState) => {
        const { loaded, data } = action.payload.result;
        if (!loaded) return;
        if (data?.message === 'FIRST_DOCUMENT') newState.showConfetti = true;
      });
    }
    default: {
      return appState;
    }
  }
};
