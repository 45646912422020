import React from 'react';
import { AppState, SyndicationMatchProfile2 } from '@hum/icm-app/src/state';
import { useDispatch, useSelector } from 'react-redux';
import { downloadMatchCompanyPublicFinancesButtonClicked } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { ButtonGroup } from './ButtonGroup';
import { useDealDetail } from './hooks/useDealDetail';
import {
  Icon,
  StatBox,
  TextType,
  Text,
  StickyFooter,
  DownloadFinancialsBar,
} from '@hum/ui-library';
import { Help } from '@hum/legacy-ui';
import emptyTeaserImg from '@hum/assets/icons/empty-teaser.png';
import ReactMarkdown from 'react-markdown';

export const DealDetail = ({
  companyId,
  matchUuid,
  matchData,
}: {
  companyId: number;
  matchUuid: string;
  matchData: SyndicationMatchProfile2;
}) => {
  const dispatch = useDispatch();

  const onClickDownloadButton = () => {
    dispatch(
      downloadMatchCompanyPublicFinancesButtonClicked({
        companyId: companyId,
        matchUuid: matchUuid,
      })
    );
  };
  const companyTeaser = useDealDetail(matchData);
  const isDownloadingCompanyFinancialsFile = useSelector(
    (state: AppState) => state.isDownloadingCompanyFinancialsFile
  );

  return (
    <>
      <div data-testid="company-activation:public-profile">
        <div className="bg-gray-700">
          <div className="mx-auto max-w-4xl">
            <section className="pt-20 pb-10 px-5">
              <div className="flex flex-col">
                <Text type={TextType.DISPLAY_M}>
                  {companyTeaser.headingText}
                </Text>
                <div className="flex items-center mt-5">
                  <Icon.Note className="mr-2" />
                  <ReactMarkdown
                    children={companyTeaser.companyPublicDescription}
                    components={{
                      p: ({ children }) => (
                        <Text type={TextType.BODY_M} subdued>
                          {children}
                        </Text>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 my-10 md:grid-cols-3">
                <StatBox
                  title="Revenue Model"
                  value={companyTeaser.revenueModel}
                />
                {companyTeaser.foundedYear && (
                  <StatBox
                    title="In business since"
                    value={companyTeaser.foundedYear}
                  />
                )}
              </div>
            </section>
          </div>
        </div>
        <div className="mx-auto max-w-4xl px-5">
          <section>
            <div className="flex flex-col py-8">
              <Text type={TextType.DISPLAY_S}>Financial Highlights</Text>
              <div className="grid grid-cols-1 gap-5 my-10 md:grid-cols-3">
                {companyTeaser.runRateRevenue && (
                  <StatBox
                    title="Run-Rate Revenue"
                    value={companyTeaser.runRateRevenue}
                    description={companyTeaser.runRateRevenueAsOf}
                    testId="company-activation:public-profile:run-rate-revenue"
                  />
                )}
                {companyTeaser.revenue && (
                  <StatBox
                    title="LTM Revenue"
                    value={companyTeaser.revenue}
                    description={companyTeaser.revenueAsOf}
                    testId="company-activation:public-profile:ltm-revenue"
                  />
                )}
                {companyTeaser.growthRateValue &&
                  companyTeaser.growthRateLabelValue && (
                    <StatBox
                      title={companyTeaser.growthRateLabelValue}
                      value={companyTeaser.growthRateValue}
                      description={companyTeaser.growthRateAsOfValue}
                      testId="company-activation:public-profile:growth-rate"
                    />
                  )}
                {companyTeaser.margins && (
                  <StatBox
                    title="LTM Gross Margins"
                    value={companyTeaser.margins}
                    description={companyTeaser.marginsAsOf}
                    testId="company-activation:public-profile:margins"
                  />
                )}
                {companyTeaser.ltvCac && (
                  <StatBox
                    title="LTV/CAC"
                    value={companyTeaser.ltvCac}
                    testId="company-activation:public-profile:ltv-cac"
                  />
                )}
                {companyTeaser.valuation && (
                  <StatBox
                    title="Latest Post-Money Valuation"
                    value={companyTeaser.valuation}
                    testId="company-activation:public-profile:valuation"
                  />
                )}
                {companyTeaser.equityRaise && (
                  <StatBox
                    title="Total Equity Raised"
                    value={companyTeaser.equityRaise}
                    testId="company-activation:public-profile:equity-raise"
                  />
                )}
              </div>
              {!companyTeaser.runRateRevenue &&
                !companyTeaser.revenue &&
                !companyTeaser.growthRateValue &&
                !companyTeaser.margins &&
                !companyTeaser.ltvCac &&
                !companyTeaser.equityRaise && (
                  <div
                    className="max-w-md mx-auto"
                    data-testid="company-activation:public-profile:empty-teaser"
                  >
                    <img
                      src={emptyTeaserImg}
                      alt="Empty Teaser"
                      className="mx-auto mb-8"
                    />
                    <Text
                      type={TextType.BODY_S}
                      className="text-center"
                      subdued
                    >
                      We are processing this company’s financial data. Please
                      check back later for more information.
                    </Text>
                  </div>
                )}
            </div>
          </section>
          {companyTeaser.showChartBook && (
            <section>
              <DownloadFinancialsBar
                onClickDownload={onClickDownloadButton}
                isDownloadingFile={isDownloadingCompanyFinancialsFile.status}
              />
            </section>
          )}
          <section className="mb-20">
            <div className="flex flex-col py-8 border border-borderColor border-l-0 border-r-0 border-b-0">
              <Text type={TextType.BODY_MICO} subdued className="mb-8">
                Securities are offered through ICM Capital Markets, LLC, Member
                FINRA/SIPC. Find out more about us on{' '}
                <a
                  href="https://brokercheck.finra.org/"
                  className="text-interactive-default"
                >
                  FINRA’s Brokercheck website
                </a>
                . The information summarized on this page represents the views
                of Hum Capital at this time; its views may change without
                notice. With any investment, there is a risk of loss. The
                information presented herein based may not be accurate or
                complete. This information does not constitute an offer to sell
                or a solicitation to buy any securities. By accepting this page,
                you acknowledge this disclaimer and agree to keep this
                information strictly confidential.
              </Text>
              <Help icon={<Icon.Info />} nofilter>
                <div className="lg:ml-2">
                  <Text type={TextType.BODY_MICO} subdued>
                    Do you have questions? Check out our
                    <a
                      href="https://info.humcapital.com/hum-help-center"
                      className="text-interactive-default"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Help Center
                    </a>
                    {' or '}
                    <a
                      href="mailto:hum-an@humcapital.com"
                      className="text-interactive-default"
                    >
                      Contact Hum
                    </a>
                  </Text>
                </div>
              </Help>
            </div>
          </section>
        </div>
      </div>
      {companyTeaser.isNewDeal && (
        <StickyFooter>
          <ButtonGroup companyId={companyId} matchUuid={matchUuid} />
        </StickyFooter>
      )}
    </>
  );
};
