// Libraries
import React, { useState } from 'react';
import * as Yup from 'yup';
import * as styles from './styles.pc';
import { useForm, useField } from '@hum/common/src/modules/form';
// Children
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { syndicationInvestorAcceptsLegalConditionsHookUsed } from '@hum/icm-app/src/actions';
import { MatchTocModal } from '@hum/icm-app/src/components/MatchTocModal';
import * as ds from '@hum/design-system';
import { Box, CheckboxField } from '@hum/legacy-ui';
import { Button } from '@hum/ui-library';

const initialValues = {
  acceptedToS: false,
};

const validationSchema = Yup.object().shape({
  acceptedToS: Yup.boolean().required(),
});

interface FormFields {
  acceptedToS: boolean;
}

interface WelcomeModalProps {
  isOpen?: boolean;
  companyId: number;
  syndicationTosVersion: string | null | undefined;
}

const LegalAgreementsModal: React.FC<WelcomeModalProps> = ({
  isOpen,
  companyId,
  syndicationTosVersion,
}) => {
  const { dispatch } = useAppStore();
  const [investorWantsToSeeTos, setInvestorWantsToSeeTos] = useState(false);
  const onSubmit = async (formData: FormFields) => {
    const payload = {};
    if (syndicationTosVersion) {
      payload['tosVersion'] = syndicationTosVersion;
      payload['tosAccepted'] = formData.acceptedToS;
    }

    // TODO: misuse of hook, this needs to be LEGAL_AGREEMENTS_MODAL_SIGNED action which triggers GET
    dispatch(
      syndicationInvestorAcceptsLegalConditionsHookUsed({
        companyId,
        payload,
      })
    );
  };

  const form = useForm({ initialValues, validationSchema, onSubmit });
  const acceptedToS = useField('acceptedToS', 'checkbox', form).input;

  return (
    <>
      <ds.Modal
        veryWide
        closable={false}
        visible={isOpen}
        testId="syndication:legal-agreements-modal"
      >
        <form onSubmit={form.handleSubmit}>
          <styles.ModalContent
            footer={
              <Box alignCenter fill>
                <Button
                  submit
                  testId="syndication:legal-agreements-modal-accept-legal-terms-button"
                  disabled={
                    !form.touched ||
                    !form.isValid ||
                    form.isSubmitting ||
                    (Boolean(syndicationTosVersion) && !form.values.acceptedToS)
                  }
                  data-id="accept_legal_terms:submit_tos"
                  loading={form.isSubmitting}
                >
                  Continue
                </Button>
              </Box>
            }
          >
            <CheckboxField
              {...acceptedToS}
              inputTestId="syndication:legal-agreements-modal-accepted-checkbox"
              label={
                <>
                  I accept Hum Capital's{' '}
                  <a
                    // href="https://www.captec.io/terms-of-service"
                    data-id="required_legal_documents:tos_to_learn_more"
                    data-testid="syndication:legal-agreements-modal-show-agreement-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      setInvestorWantsToSeeTos(true);
                      // because <a> is inside this button, if user clicks view ToS prevent checkbox from turning on
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    terms of service
                  </a>
                </>
              }
            />
          </styles.ModalContent>
        </form>
      </ds.Modal>

      {investorWantsToSeeTos && (
        <MatchTocModal
          title="Syndication Terms of Service and Non-disclosure Agreement"
          agreementVersion={syndicationTosVersion!}
          companyName=""
          isOpen={investorWantsToSeeTos}
          onClose={() => {
            setInvestorWantsToSeeTos(false);
          }}
          data-testid="syndication:legal-agreements-modal-tos"
        />
      )}
    </>
  );
};

export default LegalAgreementsModal;
