import { AppState, INITIAL_APP_STATE } from '../state';
import { Action, AuthActionType } from '../actions';
import produce from 'immer';

export const authReducer = (appState: AppState, action: Action) => {
  switch (action.type) {
    case AuthActionType.USER_SET_PASSWORD_REQUEST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        (newState.userSetPasswordResult.loaded = action.payload.result.loaded),
          (newState.session = action.payload.result);
      });
    }
    case AuthActionType.USER_REVIEW_SESSION_REQUEST_STATE_CHANGED:
    case AuthActionType.USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED:
    case AuthActionType.USER_LOGIN_REQUEST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        // only do this if loaded is true otherwise logout/login break in a weird way
        if (action.payload.result.loaded) {
          Object.assign(newState.session, action.payload.result);
        }
      });
    }
    case AuthActionType.USER_LOGOUT_REQUEST_STATE_CHANGED: {
      // reset session and current company after logging out
      if (action.payload.result.data?.success) {
        return produce(appState, (newState) => {
          newState.session = INITIAL_APP_STATE.session;
          newState.currentCompany = INITIAL_APP_STATE.currentCompany;
        });
      } else {
        return appState;
      }
    }
    case AuthActionType.API_SIGNUP_IS_INVITATION_REDEEMED_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.invitationRedemption, action.payload.result);
      });
    }
    case AuthActionType.ALLOWED_EMAIL_REQUEST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.companySignUpAllowedEmail,
          action.payload.result
        );
      });
    }
    case AuthActionType.SIGNUP_V5_RESET: {
      return produce(appState, (newState) => {
        newState.companySignUpAllowedEmail =
          INITIAL_APP_STATE.companySignUpAllowedEmail;
      });
    }

    default: {
      return appState;
    }
  }
};
