import React from 'react';
import { Icon, Spinner, Splash, TextType, Text } from '@hum/ui-library';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useSyndicationMatch } from '@hum/icm-app/src/hooks/syndication';
import { useSyndicationInsightsCharts } from '@hum/icm-app/src/hooks/useSyndicationInsightsCharts';
import { getUserCompanyId } from '@hum/icm-app/src/state';
import { ChartList } from '@hum/icm-app/src/components/ChartList';
import { CHART_TAB_TITLE_MAP } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail';

export interface CompanyInsightsProps {
  matchUuid: string;
  insightsChartsTab: string;
}

// TODO: ALL hooks used in this render function need to be used _unconditionally_.

export const MatchInsights = ({
  matchUuid,
  insightsChartsTab,
}: CompanyInsightsProps) => {
  const { state } = useAppStore();
  let matchData = state.syndicationMatch?.data;

  if (!matchData) {
    const investorCompanyId = getUserCompanyId(state);
    const { data: syndicationMatchData } = useSyndicationMatch({
      companyId: investorCompanyId,
      uuid: matchUuid,
    });
    if (!syndicationMatchData) {
      return <Spinner fullScreen />;
    }
    matchData = syndicationMatchData;
  }

  const {
    company: { id: companyId },
  } = matchData;

  const {
    data: chartsContent,
    loading: areChartsLoading,
  } = useSyndicationInsightsCharts(companyId);
  let chosenTabCharts;
  if (!chartsContent) {
    return (
      <div className="flex h-[calc(100vh-48px)]">
        <div className="flex flex-col items-center w-full justify-around">
          <Splash
            graphic={
              <Icon.InvestorSignupStepThree className="max-w-[200px] mx-auto" />
            }
            title="Nothing here yet"
            subtitle="We’ll notify you when there is new information available"
          />
        </div>
      </div>
    );
  } else if (areChartsLoading) {
    return <Spinner fullScreen />;
  } else {
    chosenTabCharts = chartsContent![insightsChartsTab];
  }

  return (
    <div className="w-full max-w-4xl mx-auto py-10 max-xl:px-4">
      <Text type={TextType.DISPLAY_S} className="mb-10 mt-6">
        {CHART_TAB_TITLE_MAP[insightsChartsTab]}
      </Text>
      {chosenTabCharts.length ? (
        <ChartList charts={chosenTabCharts} />
      ) : (
        <div className="flex h-full">
          <div className="flex flex-col items-center w-full justify-around">
            <Splash
              graphic={
                <Icon.InvestorSignupStepThree className="max-w-[200px] mx-auto" />
              }
              title="Nothing here yet"
              subtitle="We’ll notify you when there is new information available"
            />
          </div>
        </div>
      )}
    </div>
  );
};
