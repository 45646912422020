import React from 'react';
import { Breadcrumbs, BreadcrumbItem } from '@hum/legacy-ui';
import { CompanyDocumentsActionBar } from '@hum/icm-app/src/pages/syndication-v2/company-overview.pc';
import { useHistory } from 'react-router';
import { Routes } from '@hum/icm-app/src/state/routes';
import * as sb from '../sidebar.pc';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { CompanyFileDocumentType } from '@hum/types';
import { useTransactionDocuments } from '@hum/icm-app/src/hooks/useTransactionDocuments';
import {
  CompanyDocuments,
  DocumentSection,
} from '@hum/icm-app/src/components/CompanyDocuments';

type TransactionDocumentsProps = {
  matchUuid: string;
  companyId: number;
  targetCompanyId: number;
  companyName: string;
};

const DOCUMENT_SECTIONS: DocumentSection[] = [
  {
    type: CompanyFileDocumentType.TermSheet,
    description:
      'Short form document outlining the material terms and conditions of a potential transaction.',
    label: 'Term sheet',
    open: true,
    required: false,
    csvAndExcelOnly: false,
  },
  {
    type: CompanyFileDocumentType.Loan,
    description:
      'Long form document between lender and borrower that is legally binding.',
    label: 'Loan documents',
    required: false,
    csvAndExcelOnly: false,
  },
  {
    type: CompanyFileDocumentType.Other,
    description:
      'Any information that might be helpful for the transaction, e.g. Due Diligence questionnaire, fund overview, etc.',
    label: 'Additional documents',
    required: false,
    csvAndExcelOnly: false,
  },
];

export const TransactionDocuments = ({
  companyId,
  targetCompanyId,
  companyName,
  matchUuid,
}: TransactionDocumentsProps) => {
  const history = useHistory();
  const fileManager = useFileManager({ companyId });

  const haveNewFiles = fileManager.files.find((item) => {
    const {
      transaction_document,
      target_company_id,
      email_events_id,
    } = item.meta;

    return (
      transaction_document &&
      target_company_id === targetCompanyId &&
      !email_events_id
    );
  });

  const {
    onSendTransactionDocumentButtonClicked,
    fileMeta,
  } = useTransactionDocuments({ companyId, targetCompanyId, matchUuid });

  return (
    <>
      <sb.PageContent
        fixedWidth
        center
        padded
        bottomSpace
        testId="syndication:investor-deals-content"
        breadCrumbs={
          <Breadcrumbs>
            <BreadcrumbItem
              onClick={() => {
                history.push(Routes.SYNDICATION_LIVE_DEALS);
              }}
            >
              Live deals
            </BreadcrumbItem>
            <BreadcrumbItem>{companyName}</BreadcrumbItem>
          </Breadcrumbs>
        }
        title="Transaction Documents"
        description="Share and edit all of your transaction-related documents"
      >
        <CompanyDocuments
          fileManager={fileManager}
          sections={DOCUMENT_SECTIONS}
          fileMeta={fileMeta}
        />
      </sb.PageContent>
      {haveNewFiles && (
        <CompanyDocumentsActionBar
          testId="button:send-documents"
          sendClick={onSendTransactionDocumentButtonClicked}
        />
      )}
    </>
  );
};
