import { AppState } from '../state';
import { Action, DocusignActionType } from '../actions';
import produce from 'immer';

export const docusignReducer = (appState: AppState, action: Action) => {
  switch (action.type) {
    case DocusignActionType.API_DOCUSIGN_CONNECTOR_LOADED: {
      return produce(appState, (newState) => {
        newState.docusignConnector = action.payload.result;
      });
    }
    default: {
      return appState;
    }
  }
};
