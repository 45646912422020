import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';

import {
  REVENUE_MODEL,
  REVENUE_MODEL_V6,
  CompanyPublicProfileData,
} from '@hum/types';
import { Scrollable, Spinner } from '@hum/ui-library';
import { money } from '@hum/icm-app/src/utils/format';
import { formatDate } from '@hum/icm-app/src/utils';
import * as styles from '@hum/icm-app/src/pages/syndication-v2/company-detail.pc';
import { downloadCompanyPublicFinancesButtonClicked } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import * as base from '@hum/icm-app/src/pages/syndication-v2/base.pc';
import { Routes, getSession } from '@hum/icm-app/src/state';
import {
  EQUITY_RAISED,
  BUSINESS_TYPES,
  FUNDRAISING_TIME_FRAME_MONTHS,
} from '@hum/icm-app/src/components/constants';
import { getLocationOrCountry } from './syndicationUtils';
import { useEntityType } from '@hum/icm-app/src/hooks/useEntityType';
import { useAPI } from '../../hooks/useAPI';
import { PublicPageSignUp } from './PublicPageSignUp';
import { DownloadFinances } from '@hum/icm-app/src/components/DownloadFinances';
import { useCompanyPublicInfo } from '../../hooks/useCompanyPublicInfo';

export const getGrowthRate = ({
  yoyRevenueGrowthRateAsOf,
  yoyRevenueGrowthRate,
  qoqRevenueGrowthRate,
  qoqRevenueGrowthRateAsOf,
}: CompanyPublicProfileData['publicFinances']) => {
  if (!yoyRevenueGrowthRate && !qoqRevenueGrowthRate) {
    return {
      growthRate: undefined,
      growthRateAsOf: undefined,
      growthRateLabel: undefined,
    };
  }
  if ((yoyRevenueGrowthRate || 0) > (qoqRevenueGrowthRate || 0)) {
    return {
      growthRate:
        (yoyRevenueGrowthRate && `${yoyRevenueGrowthRate}%`) || undefined,
      growthRateAsOf:
        (yoyRevenueGrowthRateAsOf &&
          formatDate(yoyRevenueGrowthRateAsOf, {
            asOfTime: true,
            dateFormat: 'YYYY-MM-DD',
          })) ||
        undefined,
      growthRateLabel: 'YoY Growth Rate',
    };
  }
  return {
    growthRate:
      (qoqRevenueGrowthRate && `${qoqRevenueGrowthRate}%`) || undefined,
    growthRateAsOf:
      (qoqRevenueGrowthRateAsOf &&
        formatDate(qoqRevenueGrowthRateAsOf, {
          asOfTime: true,
          dateFormat: 'YYYY-MM-DD',
        })) ||
      undefined,
    growthRateLabel: 'QoQ Growth Rate',
  };
};

export const CompanyPublicProfile = () => {
  const { token } = useParams<{ token: string }>();
  const flags = useFlags();
  const financialHighlightsToHide = (flags.enabled('hide-financial-highlights')
    ? flags.value('hide-financial-highlights')
    : []) as string[];

  const hideFinancialHighlights = financialHighlightsToHide.includes(token);
  const showTeaserAccuracy = flags.enabled('show-teaser-accuracy');
  const showChartbook = flags.enabled('show-chartbook-match-public-link');
  const { publicData, onClickDownloadButton } = useCompanyPublicProfile();

  if (publicData) {
    const location = getLocationOrCountry(publicData.publicInfo);
    const notAvailable = 'N/A';
    const industryVertical =
      BUSINESS_TYPES[publicData.publicInfo.industryVertical!];
    const foundedYear = publicData.publicInfo.foundedYear;
    const targetDebt = money(publicData.publicInfo.investmentAmount);
    const timeFrame =
      FUNDRAISING_TIME_FRAME_MONTHS[publicData.publicInfo.fundraisingTimeFrame];
    const revenueModel =
      REVENUE_MODEL_V6[publicData.publicInfo.revenueModel!] ||
      REVENUE_MODEL[publicData.publicInfo.revenueModel!] ||
      notAvailable;
    const revenue = money(publicData.publicFinances.revenue) || undefined;
    const { growthRate, growthRateAsOf, growthRateLabel } = getGrowthRate(
      publicData.publicFinances
    );
    const margins =
      (publicData.publicFinances.margins &&
        `${publicData.publicFinances.margins}%`) ||
      undefined;
    const ltvCac = publicData.publicFinances.ltvCac || undefined;
    const revenueAsOf =
      formatDate(publicData.publicFinances.revenueAsOf as string, {
        asOfTime: true,
        dateFormat: 'YYYY-MM-DD',
      }) || undefined;
    const marginsAsOf =
      formatDate(publicData.publicFinances.marginsAsOf as string, {
        asOfTime: true,
        dateFormat: 'YYYY-MM-DD',
      }) || undefined;
    const valuation =
      money(publicData.publicInfo.latestPostMoneyValuation) || undefined;
    const equityRaised = publicData.publicInfo.totalEquityRaised
      ? EQUITY_RAISED[publicData.publicInfo.totalEquityRaised]
      : undefined;
    const runRateRevenue =
      money(publicData.publicFinances.runRateRevenue) || undefined;
    const runRateRevenueAsOf =
      formatDate(publicData.publicFinances.runRateRevenueAsOf as string, {
        asOfTime: true,
        dateFormat: 'YYYY-MM-DD',
      }) || undefined;
    const yoyRevenueGrowthRate =
      (publicData.publicFinances.yoyRevenueGrowthRate &&
        `${publicData.publicFinances.yoyRevenueGrowthRate}%`) ||
      undefined;
    const yoyRevenueGrowthRateAsOf = formatDate(
      publicData.publicFinances.yoyRevenueGrowthRateAsOf as string,
      {
        asOfTime: true,
        dateFormat: 'YYYY-MM-DD',
      }
    );
    const yoyRevenueGrowthRateLabel = 'YoY Revenue Growth';

    return (
      <base.Container>
        <Scrollable>
          <PublicPageSignUp />
          <styles.CompanyTeaser
            hideFinancialHighlights={hideFinancialHighlights}
            publicDescription={
              <ReactMarkdown
                children={publicData.publicInfo.publicDescription || ''}
              />
            }
            foundedYear={foundedYear}
            industryVertical={industryVertical}
            location={location}
            targetDebt={targetDebt}
            timeFrame={timeFrame}
            revenueModel={revenueModel}
            ltvCac={ltvCac}
            valuation={valuation}
            equityRaise={equityRaised}
            growthRate={showTeaserAccuracy ? growthRate : yoyRevenueGrowthRate}
            growthRateAsOf={
              showTeaserAccuracy ? growthRateAsOf : yoyRevenueGrowthRateAsOf
            }
            growthRateLabel={
              showTeaserAccuracy ? growthRateLabel : yoyRevenueGrowthRateLabel
            }
            margins={margins}
            marginsAsOf={marginsAsOf}
            revenue={revenue}
            revenueAsOf={revenueAsOf}
            runRateRevenue={showTeaserAccuracy && runRateRevenue}
            runRateRevenueAsOf={showTeaserAccuracy && runRateRevenueAsOf}
            downloadFinances={
              showChartbook && (
                <DownloadFinances
                  onClickDownloadButton={onClickDownloadButton}
                />
              )
            }
          />
        </Scrollable>
      </base.Container>
    );
  }
  return <Spinner fullScreen />;
};

const useCompanyPublicProfile = () => {
  const { token } = useParams<{ token: string }>();
  const api = useAPI();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSyndicationInvestor } = useEntityType();
  const currentUser = useSelector(getSession).data;
  const publicData = useCompanyPublicInfo(token);
  const onClickDownloadButton = () => {
    dispatch(
      downloadCompanyPublicFinancesButtonClicked({ companyUuid: token })
    );
  };
  useEffect(() => {
    window.location.assign(
      //eslint-disable-next-line
      `${process.env.V2_BASE_URL}/public/${token}`
    );
  }, []);
  useEffect(() => {
    // TODO: don't do this! Side effects must always be called in the business layer
    if (currentUser?.id && isSyndicationInvestor && publicData) {
      api.syndication
        .sendRequestIntroduction(dispatch)(currentUser!.company!.id, token)
        .then((response) => {
          history.push(
            `${Routes.SYNDICATION_INVESTOR_MATCH}?uuid=${response.data.uuid}`
          );
        });
    }
  }, [publicData, isSyndicationInvestor, currentUser]);

  return {
    publicData,
    onClickDownloadButton,
  };
};
