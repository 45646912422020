import React from 'react';
import { Button, ButtonType } from '@hum/ui-library';
import { useButtonGroup } from './hooks/useButtonGroup';

export const ButtonGroup = ({
  companyId,
  matchUuid,
}: {
  companyId: number;
  matchUuid: string;
}) => {
  const { investorMatchHandler } = useButtonGroup({ companyId, matchUuid });

  return (
    <div className="flex justify-center p-4 gap-5">
      <Button
        type={ButtonType.SECONDARY}
        onClick={() => investorMatchHandler(false)}
      >
        I'm not interested
      </Button>
      <Button
        type={ButtonType.PRIMARY}
        onClick={() => investorMatchHandler(true)}
      >
        I want to meet this company
      </Button>
    </div>
  );
};
