import React from "react";
import _pub7Ab5Bb97 from "./base.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import _pubDac6F880 from "../../../../design-system/src/Alert.pc";
import _pubC68937F5 from "../../../../design-system/src/Scrollable.pc";
import _pubA8875Cb4 from "../../../../design-system/src/Breadcrumbs.pc";
import _pub9F4190D6 from "../../../../design-system/src/icons.pc";
import _pub7D0904Ad from "../../../../design-system/src/Help.pc";
import "./PublicPageSignUp.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var TopBaseBar = React.memo(React.forwardRef(function TopBaseBar(props, ref) {
  return React.createElement("div", {
    "className": "_43eabd46 _9ddc597e _pub-9ddc597e",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_23de2c72 _9ddc597e _pub-9ddc597e",
    }, 
      props["children"]
    )
  )
}));
export { TopBaseBar };

var TopBarIntro = React.memo(React.forwardRef(function TopBarIntro(props, ref) {
  return React.createElement("div", {
    "className": "_d4f96ba7 _9ddc597e _pub-9ddc597e",
    "ref": ref,
  }, 
    React.createElement(TopBaseBar, {
      "class": "_7c0c3ebb",
    }, 
      React.createElement("div", {
        "className": "_817a6e68 _9ddc597e _pub-9ddc597e" + " " + "_9ddc597e_request_access--title _pub-9ddc597e_request_access--title request_access--title",
      }, 
        React.createElement("b", {
          "className": "_370364a0 _9ddc597e _pub-9ddc597e",
        }, 
          "Request access."
          
        ),
        " It only takes 30 seconds\n      "
        ,
        React.createElement("p", {
          "className": "_d90d058c _9ddc597e _pub-9ddc597e",
        }, 
          "meet this company and "
          ,
          React.createElement("i", {
            "className": "_d771b2ef _9ddc597e _pub-9ddc597e",
          }, 
            "1,500+"
            
          ),
          " more"
          
        )
      ),
      props["children"]
    )
  )
}));
export { TopBarIntro };

var RequestAccess = React.memo(React.forwardRef(function RequestAccess(props, ref) {
  return React.createElement("div", {
    "className": "_3af70a8b _9ddc597e _pub-9ddc597e",
    "ref": ref,
  }, 
    React.createElement(TopBaseBar, {
      "class": "_7f88ead5",
    }, 
      React.createElement("div", {
        "className": "_fbba3d08 _9ddc597e _pub-9ddc597e" + " " + "_9ddc597e_request_access--title _pub-9ddc597e_request_access--title request_access--title",
      }, 
        React.createElement("div", {
          "className": "_a09c7589 _9ddc597e _pub-9ddc597e",
        }, 
          React.createElement("b", {
            "className": "_8d047e91 _9ddc597e _pub-9ddc597e",
          }, 
            "Request access."
            
          ),
          " It only takes 30 seconds"
          
        ),
        React.createElement("p", {
          "className": "_39952433 _9ddc597e _pub-9ddc597e",
        }, 
          "meet this company and "
          ,
          React.createElement("i", {
            "className": "_f845f05e _9ddc597e _pub-9ddc597e",
          }, 
            "1,500+"
            
          ),
          " more"
          
        ),
        props["children"]
      )
    )
  )
}));
export { RequestAccess };

var SignUpCompleted = React.memo(React.forwardRef(function SignUpCompleted(props, ref) {
  return React.createElement("div", {
    "className": "_d394afbe _9ddc597e _pub-9ddc597e",
    "ref": ref,
  }, 
    React.createElement(TopBaseBar, {
      "class": "_7b059667",
    }, 
      React.createElement("div", {
        "className": "_74fac8a8 _9ddc597e _pub-9ddc597e",
      }, 
        React.createElement("div", {
          "className": "_c34c40b3 _9ddc597e _pub-9ddc597e",
        }, 
          "\n        We've received your request\n      "
          
        ),
        React.createElement("div", {
          "className": "_5a451109 _9ddc597e _pub-9ddc597e",
        }, 
          "\n        We’ll reach out to you within 24 hours to set up your account and facilitate\n        this intro and others that may be of interest.\n      "
          
        )
      )
    )
  )
}));
export { SignUpCompleted };

var AccessButtons = React.memo(React.forwardRef(function AccessButtons(props, ref) {
  return React.createElement("div", {
    "className": "_3d9ace92 _9ddc597e _pub-9ddc597e" + (props["bottom"] ? " " + "_9ddc597e_bottom _pub-9ddc597e_bottom bottom" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_f86729f _9ddc597e _pub-9ddc597e",
      "v3": true,
      "primary": true,
      "resetCase": true,
      "wide": props["smaller"],
      "onClick": props["toggleShowRegister"],
    }, 
      "\n    Request access\n  "
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_968f2325 _9ddc597e _pub-9ddc597e",
      "v3": true,
      "transparent": true,
      "wide": props["smaller"],
      "onClick": props["clickInGoToLogin"],
    }, 
      "\n    Login\n  "
      
    )
  )
}));
export { AccessButtons };


