import React from "react";
import _pub7Ab5Bb97, {Shhhh as _pub7Ab5Bb97_Shhhh,Detail as _pub7Ab5Bb97_Detail,Container as _pub7Ab5Bb97_Container} from "./base.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import _pubDac6F880 from "../../../../design-system/src/Alert.pc";
import _pubC68937F5, {ScrollablePreview as _pubC68937F5_ScrollablePreview} from "../../../../design-system/src/Scrollable.pc";
import _pubA8875Cb4, {BreadcrumbItem as _pubA8875Cb4_BreadcrumbItem} from "../../../../design-system/src/Breadcrumbs.pc";
import _pub9F4190D6, {DescriptionIcon as _pub9F4190D6_DescriptionIcon} from "../../../../design-system/src/icons.pc";
import _pub7D0904Ad from "../../../../design-system/src/Help.pc";
import "./company-detail.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Section = React.memo(React.forwardRef(function Section(props, ref) {
  return React.createElement("div", {
    "className": "_40975d7a _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_8720841e _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      props["title"]
    ),
    React.createElement("div", {
      "className": "_f027b488 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      props["details"]
    )
  )
}));
export { Section };

var DetailTeaser = React.memo(React.forwardRef(function DetailTeaser(props, ref) {
  return React.createElement("div", {
    "className": "_ae993c56 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["light"] ? " " + "_d7465d49_light _pub-d7465d49_light _pub-a97d53f3_light light" : "") + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_f3a360e6 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      props["label"]
    ),
    React.createElement("div", {
      "className": "_6aaa315c _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      props["value"]
    )
  )
}));
export { DetailTeaser };

var CompanyDescription = React.memo(React.forwardRef(function CompanyDescription(props, ref) {
  return React.createElement("div", {
    "className": "_47fa9963 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_company-description _pub-d7465d49_company-description _pub-a97d53f3_company-description company-description",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CompanyDescription };

var Banner = React.memo(React.forwardRef(function Banner(props, ref) {
  return React.createElement("div", {
    "className": "_a9f4f84f _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubDac6F880, {
      "class": "_f4aac83a _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "positive": props["positive"],
      "info": !props["positive"],
      "title": props["title"],
      "testId": props["testId"],
      "description": props["description"],
    }, null)
  )
}));
export { Banner };

var CompanyDetails = React.memo(React.forwardRef(function CompanyDetails(props, ref) {
  return React.createElement("div", {
    "className": "_4e4cd548 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_content _pub-d7465d49_content _pub-a97d53f3_content content",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_8933d5a6 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(CompanyInfoMain, {
        "class": "_3ec9fc2d",
      }, 
        React.createElement("div", {
          "className": "_d30ba2ad _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          props["name"]
        ),
        !props["name"]&&React.createElement(_pub7Ab5Bb97_Shhhh, {
          "class": "_45743a97 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, null),
        React.createElement("p", {
          "className": "_3d05c381 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          props["description"]
        ),
        React.createElement(InsightsSide, {
          "class": "_4a02f317",
          "location": props["location"],
          "industryVertical": props["industryVertical"],
          "revenueModel": props["revenueModel"],
          "totalEquityRaised": props["totalEquityRaised"],
        }, null),
        props["buttons"]
      ),
      React.createElement(InsightsSide, {
        "class": "_a7c0ad97",
        "location": props["location"],
        "industryVertical": props["industryVertical"],
        "revenueModel": props["revenueModel"],
        "totalEquityRaised": props["totalEquityRaised"],
      }, null)
    )
  )
}));
export { CompanyDetails };

var CompanyDetailsTeaser = React.memo(React.forwardRef(function CompanyDetailsTeaser(props, ref) {
  return React.createElement("div", {
    "className": "_6bba0eb9 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_content _pub-d7465d49_content _pub-a97d53f3_content content" + (props["extraSpace"] ? " " + "_d7465d49_extra-space _pub-d7465d49_extra-space _pub-a97d53f3_extra-space extra-space" : "") + (props["extraTop"] ? " " + "_d7465d49_extra-top _pub-d7465d49_extra-top _pub-a97d53f3_extra-top extra-top" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e2921b66 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement("h3", {
        "className": "_89efaf75 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_240cf20f _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          props["industryVertical"]&&React.createElement("span", {
            "className": "_122b82f3 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          }, 
            props["industryVertical"],
            " business\n        "
            
          ),
          !props["industryVertical"]&&React.createElement("span", {
            "className": "_b30b3b2 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          }, 
            "A company "
            
          ),
          props["location"]&&React.createElement("span", {
            "className": "_201de071 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          }, 
            "based in "
            ,
            props["location"]
          ),
          " is looking to raise "
          ,
          props["targetDebt"],
          props["timeFrame"]&&React.createElement("span", {
            "className": "_6f5c76b6 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          }, 
            "\n          in "
            ,
            props["timeFrame"]
          )
        )
      ),
      React.createElement("div", {
        "className": "_10e6fecf _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        props["publicDescription"]&&React.createElement(_pub9F4190D6_DescriptionIcon, {
          "class": "_76f561ac _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, null),
        React.createElement("div", {
          "className": "_bf431dec _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          React.createElement("span", {
            "className": "_a1e02750 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          }, 
            props["publicDescription"]
          )
        )
      ),
      React.createElement(FinancialsBottom, {
        "class": "_67e1ce59",
      }, 
        React.createElement(FinancialDescription, {
          "class": "_508f16f7",
          "label": "Revenue Model",
          "value": props["revenueModel"],
        }, null),
        props["foundedYear"]&&React.createElement(FinancialDescription, {
          "class": "_ce4906c9",
          "label": "In business since",
          "value": props["foundedYear"],
        }, null),
        React.createElement(FinancialDescription, {
          "class": "_be8177db",
          "label": "",
          "value": "",
        }, null)
      )
    )
  )
}));
export { CompanyDetailsTeaser };

var CompanyInfoSection = React.memo(React.forwardRef(function CompanyInfoSection(props, ref) {
  return React.createElement("div", {
    "className": "_85b46f95 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_content _pub-d7465d49_content _pub-a97d53f3_content content" + (props["extraSpace"] ? " " + "_d7465d49_extra-space _pub-d7465d49_extra-space _pub-a97d53f3_extra-space extra-space" : "") + (props["extraTop"] ? " " + "_d7465d49_extra-top _pub-d7465d49_extra-top _pub-a97d53f3_extra-top extra-top" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e116cf08 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement("h3", {
        "className": "_f32ffc15 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        props["name"]
      ),
      React.createElement(FinancialsBottom, {
        "class": "_6a26adaf",
      }, 
        props["children"]
      )
    )
  )
}));

var DisclaimerSection = React.memo(React.forwardRef(function DisclaimerSection(props, ref) {
  return React.createElement("div", {
    "className": "_6cd7caa0 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e59bb3ba _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, null),
    React.createElement("div", {
      "className": "_7c92e200 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      "\n    Securities are offered through ICM Capital Markets, LLC, Member FINRA/SIPC. Find\n    out more about us on "
      ,
      React.createElement("a", {
        "className": "_e720e656 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        "href": "https://brokercheck.finra.org/",
      }, 
        "\n      FINRA’s Brokercheck website\n    "
        
      ),
      ". The information summarized on this page represents the views of Hum\n    Capital at this time; its views may change without notice. With any\n    investment, there is a risk of loss. The information presented herein based\n    may not be accurate or complete. This information does not constitute an\n    offer to sell or a solicitation to buy any securities. By accepting this\n    page, you acknowledge this disclaimer and agree to keep this information\n    strictly confidential.\n  "
      
    ),
    React.createElement("div", {
      "className": "_b95d296 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(_pub7D0904Ad, {
        "class": "_7febdddb _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        "nofilter": true,
        "icon": React.createElement("img", {
          "className": "_92a20f36 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_Icon _pub-d7465d49_Icon _pub-a97d53f3_Icon Icon",
          "src": getDefault(require("@hum/assets/icons/info-circle-gray.png")),
        }, null),
      }, 
        React.createElement("div", {
          "className": "_dd84b5ea _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          "\n        Do you have questions?\n        "
          ,
          React.createElement("a", {
            "className": "_2fb7c9cb _d7465d49 _pub-d7465d49 _pub-a97d53f3",
            "onClick": props["contactSalesClick"],
            "href": "mailto: support@humcapital.com",
          }, 
            "\n          Contact Sales\n        "
            
          )
        )
      )
    )
  )
}));

var CompanyInfoSectionTeaser = React.memo(React.forwardRef(function CompanyInfoSectionTeaser(props, ref) {
  return React.createElement("div", {
    "className": "_82d9ab8c _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_content _pub-d7465d49_content _pub-a97d53f3_content content" + (props["extraSpace"] ? " " + "_d7465d49_extra-space _pub-d7465d49_extra-space _pub-a97d53f3_extra-space extra-space" : "") + (props["extraTop"] ? " " + "_d7465d49_extra-top _pub-d7465d49_extra-top _pub-a97d53f3_extra-top extra-top" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e61f67d4 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      !props["hideFinancialHighlights"]&&(React.createElement("div", {
        "className": "_1b7f6761 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        React.createElement("h3", {
          "className": "_9f1dfd48 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          props["name"]
        ),
        React.createElement(FinancialsBottom, {
          "class": "_614acf2",
        }, 
          props["children"]
        )
      )),
      props["downloadFinances"]&&React.createElement("div", {
        "className": "_2645620 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_27a19a2d _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_text-body-highlight _pub-d7465d49_text-body-highlight _pub-a97d53f3_text-body-highlight text-body-highlight _d7465d49_my-5 _pub-d7465d49_my-5 _pub-a97d53f3_my-5 my-5",
        }, 
          "Supporting materials"
          
        ),
        props["downloadFinances"]
      ),
      React.createElement(DisclaimerSection, {
        "class": "_9fa60b6f",
      }, null)
    )
  )
}));

var FinancialInformation = React.memo(React.forwardRef(function FinancialInformation(props, ref) {
  return React.createElement(CompanyInfoSection, {
    "class": "_6561868b",
    "ref": ref,
    "name": props["name"],
    "extraSpace": props["extraSpace"],
    "extraTop": props["extraTop"],
  }, 
    React.createElement(FinancialDescription, {
      "class": "_9b867a48",
      "label": "Last 12 Month (LTM) Revenue",
      "value": props["revenue"],
    }, null),
    React.createElement(FinancialDescription, {
      "class": "_ec814ade",
      "label": "Y-o-Y Growth Rate",
      "value": props["growthRate"],
    }, null),
    React.createElement(FinancialDescription, {
      "class": "_75881b64",
      "label": "Gross Margins",
      "value": props["margins"],
    }, null),
    React.createElement(FinancialDescription, {
      "class": "_28f2bf2",
      "label": "Last 12 Month (LTM) EBITDA",
      "value": props["burn"],
    }, null)
  )
}));
export { FinancialInformation };

var FinancialInformationTeaser = React.memo(React.forwardRef(function FinancialInformationTeaser(props, ref) {
  return React.createElement(CompanyInfoSectionTeaser, {
    "class": "_72a13ff8",
    "ref": ref,
    "name": props["name"],
    "extraSpace": props["extraSpace"],
    "extraTop": props["extraTop"],
    "downloadFinances": props["downloadFinances"],
    "hideFinancialHighlights": props["hideFinancialHighlights"],
    "contactSalesClick": props["contactSalesClick"],
  }, 
    props["runRateRevenue"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_749391a6",
      "label": "Run-Rate Revenue",
      "value": props["runRateRevenue"],
      "asOf": props["runRateRevenueAsOf"],
    }, null),
    props["revenue"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_6d88a0e7",
      "label": "LTM Revenue",
      "asOf": props["revenueAsOf"],
      "value": props["revenue"],
    }, null),
    props["growthRate"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_46a5f324",
      "label": props["growthRateLabel"],
      "asOf": props["growthRateAsOf"],
      "value": props["growthRate"],
    }, null),
    props["margins"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_5fbec265",
      "label": "LTM Gross Margins",
      "asOf": props["marginsAsOf"],
      "value": props["margins"],
    }, null),
    props["ltvCac"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_10ff54a2",
      "label": "LTV/CAC",
      "value": props["ltvCac"],
    }, null),
    props["valuation"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_9e465e3",
      "label": "Latest Post-Money Valuation",
      "value": props["valuation"],
    }, null),
    props["equityRaise"]&&React.createElement(FinancialDescriptionTeaser, {
      "class": "_22c93620",
      "label": "Total Equity Raised",
      "value": props["equityRaise"],
    }, null),
    (!props["revenue"]&&!props["growthRate"]&&!props["margins"]&&!props["ltvCac"]&&!props["valuation"]&&!props["equityRaise"])&&React.createElement("div", {
      "className": "_3bd20761 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement("img", {
        "className": "_d2afbcbd _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        "src": getDefault(require("@hum/assets/icons/empty-teaser.png")),
        "style": castStyle("margin: auto; margin-top: 62px; margin-bottom: 32px;"),
      }, null),
      "\n    We are processing this company’s financial data. Please check back later for\n    more information.\n  "
      
    )
  )
}));
export { FinancialInformationTeaser };

var DealInformation = React.memo(React.forwardRef(function DealInformation(props, ref) {
  return React.createElement(CompanyInfoSection, {
    "class": "_9caf5ed4",
    "ref": ref,
    "name": props["name"],
    "extraSpace": props["extraSpace"],
    "extraTop": props["extraTop"],
  }, 
    React.createElement(FinancialDescription, {
      "class": "_2ead98fb",
      "label": "Existing Debt",
      "value": props["existingDebt"],
    }, null),
    React.createElement(FinancialDescription, {
      "class": "_59aaa86d",
      "label": "Target Raise",
      "value": props["targetDebt"],
    }, null),
    React.createElement(FinancialDescription, {
      "class": "_c0a3f9d7",
      "label": "Use of funds",
      "value": props["useOfFunds"],
    }, null),
    React.createElement(FinancialDescription, {
      "class": "_29c05ce2",
    }, null)
  )
}));
export { DealInformation };

var BottomCtaBar = React.memo(React.forwardRef(function BottomCtaBar(props, ref) {
  return React.createElement("div", {
    "className": "_75ccfbe1 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["bottom"] ? " " + "_d7465d49_bottom _pub-d7465d49_bottom _pub-a97d53f3_bottom bottom" : "") + (props["fixed"] ? " " + "_d7465d49_fixed _pub-d7465d49_fixed _pub-a97d53f3_fixed fixed" : "") + (props["fullwidth"] ? " " + "_d7465d49_fullwidth _pub-d7465d49_fullwidth _pub-a97d53f3_fullwidth fullwidth" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_5d27d4df _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      props["label"]&&React.createElement("span", {
        "className": "_e9dbfc9f _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        props["label"]
      ),
      props["children"]
    )
  )
}));
export { BottomCtaBar };

var CompanyInfoMain = React.memo(React.forwardRef(function CompanyInfoMain(props, ref) {
  return React.createElement("div", {
    "className": "_9bc29acd _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CompanyInfoMain };

var InsightsSide = React.memo(React.forwardRef(function InsightsSide(props, ref) {
  return React.createElement("div", {
    "className": "_7c7ab7ca _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_insights_side _pub-d7465d49_insights_side _pub-a97d53f3_insights_side insights_side " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement(_pub7Ab5Bb97_Detail, {
      "class": "_cd347c01 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "label": "Location",
      "value": props["location"],
    }, null),
    React.createElement(_pub7Ab5Bb97_Detail, {
      "class": "_ba334c97 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "label": "Industry Vertical",
      "value": props["industryVertical"],
    }, null),
    React.createElement(_pub7Ab5Bb97_Detail, {
      "class": "_2457d934 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "label": "Revenue Model",
      "value": props["revenueModel"],
    }, null),
    React.createElement(_pub7Ab5Bb97_Detail, {
      "class": "_5350e9a2 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "label": "Equity Raised",
      "value": props["totalEquityRaised"],
    }, null)
  )
}));
export { InsightsSide };

var FinancialsBottom = React.memo(React.forwardRef(function FinancialsBottom(props, ref) {
  return React.createElement("div", {
    "className": "_3de0a93f _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_financials--bottom _pub-d7465d49_financials--bottom _pub-a97d53f3_financials--bottom financials--bottom",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { FinancialsBottom };

var FinancialDescription = React.memo(React.forwardRef(function FinancialDescription(props, ref) {
  return React.createElement(React.Fragment, {
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_b37aa042 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_financial--description _pub-d7465d49_financial--description _pub-a97d53f3_financial--description financial--description",
    }, 
      React.createElement("div", {
        "className": "_24b4753f _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        React.createElement(DetailTeaser, {
          "class": "_489cc311" + " " + "_d7465d49_financial--detail _pub-d7465d49_financial--detail _pub-a97d53f3_financial--detail financial--detail",
          "label": props["label"],
          "value": props["value"],
        }, null)
      )
    )
  )
}));
export { FinancialDescription };

var FinancialDescriptionTeaser = React.memo(React.forwardRef(function FinancialDescriptionTeaser(props, ref) {
  return React.createElement(React.Fragment, {
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_b7f7dcf0 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + " " + "_d7465d49_financial--description _pub-d7465d49_financial--description _pub-a97d53f3_financial--description financial--description",
    }, 
      React.createElement("div", {
        "className": "_abf4809f _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        React.createElement("span", {
          "className": "_2b4cf62b _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          React.createElement(DetailTeaser, {
            "class": "_6584f9b0" + " " + "financial--detail",
            "label": props["label"],
            "value": props["value"],
          }, null),
          React.createElement("div", {
            "className": "_1283c926 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          }, 
            props["asOf"]&&React.createElement("p", {
              "className": "_7d19bf62 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
            }, 
              "As of "
              ,
              props["asOf"]
            )
          )
        )
      )
    )
  )
}));
export { FinancialDescriptionTeaser };

var MatchButton = React.memo(React.forwardRef(function MatchButton(props, ref) {
  return React.createElement("div", {
    "className": "_d4830c0a _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["bottom"] ? " " + "_d7465d49_bottom _pub-d7465d49_bottom _pub-a97d53f3_bottom bottom" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_c3743808 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "v3": true,
      "primary": true,
      "resetCase": true,
      "wide": props["smaller"],
      "onClick": props["makeMatch"],
    }, 
      "\n    Make match\n  "
      
    )
  )
}));
export { MatchButton };

var IntroduceMeButtons = React.memo(React.forwardRef(function IntroduceMeButtons(props, ref) {
  return React.createElement("div", {
    "className": "_333b210d _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["bottom"] ? " " + "_d7465d49_bottom _pub-d7465d49_bottom _pub-a97d53f3_bottom bottom" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_c9ea1502 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "data-testid": "syndication-indroduce-me-button",
      "v3": true,
      "primary": true,
      "resetCase": true,
      "wide": props["smaller"],
      "onClick": props["onIntroduceMeClick"],
    }, 
      "\n    Introduce me\n  "
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_50e344b8 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "data-testid": "syndication-not-interested-button",
      "v3": true,
      "resetCase": true,
      "wide": props["smaller"],
      "onClick": props["onNotInterestedClick"],
    }, 
      "\n    Not interested\n  "
      
    )
  )
}));
export { IntroduceMeButtons };

var IntroduceMeButtonsTeaser = React.memo(React.forwardRef(function IntroduceMeButtonsTeaser(props, ref) {
  return React.createElement("div", {
    "className": "_24fb987e _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["bottom"] ? " " + "_d7465d49_bottom _pub-d7465d49_bottom _pub-a97d53f3_bottom bottom" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_7f4523df _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "data-testid": "syndication-not-interested-button",
      "v3": true,
      "resetCase": true,
      "wide": props["smaller"],
      "onClick": props["onNotInterestedClick"],
    }, 
      "\n    I'm not interested\n  "
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_e64c7265 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "data-testid": "syndication-indroduce-me-button",
      "v3": true,
      "primary": true,
      "resetCase": true,
      "wide": props["smaller"],
      "onClick": props["onIntroduceMeClick"],
    }, 
      "\n    I want to meet this company\n  "
      
    )
  )
}));
export { IntroduceMeButtonsTeaser };

var RequestChangesButtons = React.memo(React.forwardRef(function RequestChangesButtons(props, ref) {
  return React.createElement("div", {
    "className": "_caf5f952 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["bottom"] ? " " + "_d7465d49_bottom _pub-d7465d49_bottom _pub-a97d53f3_bottom bottom" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_7cc1f7b1 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "v3": true,
      "primary": true,
      "resetCase": true,
      "wide": true,
    }, 
      "Introduce me"
      
    )
  )
}));
export { RequestChangesButtons };

var CompanyChartsSection = React.memo(React.forwardRef(function CompanyChartsSection(props, ref) {
  return React.createElement("div", {
    "className": "_23965c67 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_f4bbb95 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_60a8533a _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        React.createElement("h3", {
          "className": "_90e1c623 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        }, 
          props["name"]
        )
      )
    ),
    props["chartsList"],
    React.createElement("div", {
      "className": "_e145dab9 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_632c8754 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        "\n      See something wrong? Request a change at "
        ,
        React.createElement("a", {
          "className": "_42ff46f _d7465d49 _pub-d7465d49 _pub-a97d53f3",
          "href": "mailto: support@humcapital.com",
        }, 
          "\n        support@humcapital.com\n      "
          
        )
      )
    )
  )
}));
export { CompanyChartsSection };

var EmptyCharts = React.memo(React.forwardRef(function EmptyCharts(props, ref) {
  return React.createElement("div", {
    "className": "_cd983d4b _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("h3", {
      "className": "_7bc85f6d _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      "\n    No charts available\n  "
      
    ),
    React.createElement("p", {
      "className": "_e2c10ed7 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      "\n    Our quants are still analyzing your data\n  "
      
    ),
    React.createElement("div", {
      "className": "_95c63e41 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_182ebe03 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
        "v3": true,
        "primary": true,
        "resetCase": true,
        "wide": true,
        "onClick": props["dataroomRedirect"],
        "testId": props["testId"],
      }, 
        "\n      Configure Dataroom\n    "
        
      )
    )
  )
}));
export { EmptyCharts };

var PublicCompanyView = React.memo(React.forwardRef(function PublicCompanyView(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_2a20104c _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement(_pubC68937F5_ScrollablePreview, {
      "class": "_65142f1 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(Banner, {
        "class": "_d25f8ead",
        "positive": true,
        "title": "Your profile is public!",
        "description": "our business team has confirmed the charts below",
      }, null),
      React.createElement(CompanyDetails, {
        "class": "_a558be3b",
        "name": "Company Name",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. Consectetur adipiscing elit, sed do eiusmod.",
        "location": "New York NY",
        "industryVertical": "Drogas",
        "revenueModel": "Street corner dealing",
        "totalEquityRaised": "A billi",
        "smaller": props["smaller"],
      }, null),
      React.createElement(FinancialInformation, {
        "class": "_3c51ef81",
        "name": "Key Financial Highlights",
        "revenue": "revenue",
        "growthRate": "growth_rate",
        "margins": "margins",
        "burn": "burn",
        "extraTop": true,
      }, null),
      React.createElement(DealInformation, {
        "class": "_4b56df17",
        "name": "Deal Information",
        "existingDebt": "100000",
        "targetDebt": "100000",
        "useOfFunds": "Hustling",
        "extraSpace": true,
      }, null),
      React.createElement(CompanyChartsSection, {
        "class": "_d5324ab4",
        "name": "Pre-intro Insights",
        "chartsList": React.createElement(EmptyCharts, {
          "class": "_4f05aa68",
        }, null),
      }, null)
    )
  )
}));
export { PublicCompanyView };

var CompanyTeaser = React.memo(React.forwardRef(function CompanyTeaser(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_fd6cbbd _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "width100": props["width100"],
  }, 
    React.createElement(CompanyDetailsTeaser, {
      "class": "_1af7bca7",
      "industryVertical": props["industryVertical"],
      "location": props["location"],
      "targetDebt": props["targetDebt"],
      "timeFrame": props["timeFrame"],
      "foundedYear": props["foundedYear"],
      "revenueModel": props["revenueModel"],
      "publicDescription": props["publicDescription"],
    }, null),
    React.createElement(FinancialInformationTeaser, {
      "class": "_6df08c31",
      "name": "Financial Highlights",
      "hideFinancialHighlights": props["hideFinancialHighlights"],
      "ltvCac": props["ltvCac"],
      "valuation": props["valuation"],
      "equityRaise": props["equityRaise"],
      "margins": props["margins"],
      "marginsAsOf": props["marginsAsOf"],
      "growthRate": props["growthRate"],
      "growthRateAsOf": props["growthRateAsOf"],
      "growthRateLabel": props["growthRateLabel"],
      "revenue": props["revenue"],
      "revenueAsOf": props["revenueAsOf"],
      "contactSalesClick": props["contactSalesClick"],
      "runRateRevenue": props["runRateRevenue"],
      "runRateRevenueAsOf": props["runRateRevenueAsOf"],
      "downloadFinances": props["downloadFinances"],
    }, null)
  )
}));
export { CompanyTeaser };

var PublicCompanyViewTeaser = React.memo(React.forwardRef(function PublicCompanyViewTeaser(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_e1d8aa91 _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement(_pubC68937F5_ScrollablePreview, {
      "class": "_197368c9 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(CompanyDetailsTeaser, {
        "class": "_1e7be4a2",
        "industryVertical": "Saas",
        "location": "NY",
        "timeFrame": "the next 6 months",
        "targetDebt": "1000",
        "revenueModel": "Make money",
        "foundedYear": "2010",
      }, null),
      !props["hideFinancialHighlights"]&&(React.createElement(FinancialInformationTeaser, {
        "class": "_d5065740",
        "name": "Financial Highlights",
        "revenue": "revenue",
        "growthRate": "growth_rate",
        "margins": "margins",
        "valuation": "750K",
        "equityRaise": "250K",
      }, null))
    ),
    React.createElement(BottomCtaBar, {
      "class": "_6e74585f",
    }, 
      React.createElement(IntroduceMeButtonsTeaser, {
        "class": "_1fb98e95",
        "smaller": props["smaller"],
        "bottom": true,
      }, null)
    )
  )
}));
export { PublicCompanyViewTeaser };

var Public = React.memo(React.forwardRef(function Public(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_16cdfafc _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["humDark"] ? " " + "_d7465d49_hum _pub-d7465d49_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_d7465d49_dark _pub-d7465d49_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pubC68937F5_ScrollablePreview, {
      "class": "_a24bdbc2 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(CompanyDetails, {
        "class": "_afe76467",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. Consectetur adipiscing elit, sed do eiusmod.",
        "location": "New York NY",
        "industryVertical": "Drogas",
        "revenueModel": "Street corner dealing",
        "totalEquityRaised": "A billi",
        "smaller": props["smaller"],
      }, null),
      React.createElement(FinancialInformation, {
        "class": "_d8e054f1",
        "name": "Key Financial Highlights",
        "revenue": "revenue",
        "growthRate": "growth_rate",
        "margins": "margins",
        "burn": "burn",
        "extraTop": true,
      }, null),
      React.createElement(DealInformation, {
        "class": "_41e9054b",
        "name": "Deal Information",
        "existingDebt": "100000",
        "targetDebt": "100000",
        "useOfFunds": "Hustling",
        "extraSpace": true,
      }, null)
    )
  )
}));
export { Public };

var BreadCrumb = React.memo(React.forwardRef(function BreadCrumb(props, ref) {
  return React.createElement("div", {
    "className": "_9155e633 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubA8875Cb4, {
      "class": "_8d2ffb02 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      "padded": true,
    }, 
      React.createElement(_pubA8875Cb4_BreadcrumbItem, {
        "class": "_5f73bc85 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        props["sectionName"]
      ),
      React.createElement(_pubA8875Cb4_BreadcrumbItem, {
        "class": "_28748c13 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
      }, 
        props["name"]
      )
    )
  )
}));
export { BreadCrumb };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_7f5b871f _d7465d49 _pub-d7465d49 _pub-a97d53f3" + (props["humDark"] ? " " + "_d7465d49_hum _pub-d7465d49_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_d7465d49_dark _pub-d7465d49_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pubC68937F5_ScrollablePreview, {
      "class": "_f9ac1ffa _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(CompanyDetails, {
        "class": "_247185d2",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. Consectetur adipiscing elit, sed do eiusmod.",
        "buttons": React.createElement(IntroduceMeButtons, {
          "class": "_eb252dca",
          "smaller": props["smaller"],
        }, null),
        "location": "New York NY",
        "industryVertical": "Drogas",
        "revenueModel": "Street corner dealing",
        "totalEquityRaised": "A billi",
        "smaller": props["smaller"],
      }, null),
      React.createElement(FinancialInformation, {
        "class": "_5376b544",
        "name": "Key Financial Highlights",
        "revenue": "revenue",
        "growthRate": "growth_rate",
        "margins": "margins",
        "burn": "burn",
        "extraTop": true,
      }, null),
      React.createElement(DealInformation, {
        "class": "_ca7fe4fe",
        "name": "Deal Information",
        "existingDebt": "100000",
        "targetDebt": "100000",
        "useOfFunds": "Hustling",
        "extraSpace": true,
      }, null)
    ),
    React.createElement(BottomCtaBar, {
      "class": "_8eab2f6c",
      "label": "Are you interested in this company? Let's make and intro.",
    }, 
      React.createElement(IntroduceMeButtons, {
        "class": "_25b3efe5",
        "smaller": props["smaller"],
        "bottom": true,
      }, null)
    )
  )
}));
export { Preview };

var NewMatch = React.memo(React.forwardRef(function NewMatch(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_884ed772 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubC68937F5_ScrollablePreview, {
      "class": "_4294acf1 _d7465d49 _pub-d7465d49 _pub-a97d53f3",
    }, 
      React.createElement(CompanyDetails, {
        "class": "_95ed0517",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam cras feugiat sit ac semper. Consectetur adipiscing elit, sed do eiusmod.",
        "buttons": React.createElement(MatchButton, {
          "class": "_9a8775",
          "smaller": props["smaller"],
        }, null),
        "location": "New York NY",
        "industryVertical": "Drogas",
        "revenueModel": "Street corner dealing",
        "totalEquityRaised": "A billi",
        "smaller": props["smaller"],
      }, null),
      React.createElement(FinancialInformation, {
        "class": "_e2ea3581",
        "name": "Key Financial Highlights",
        "revenue": "revenue",
        "growthRate": "growth_rate",
        "margins": "margins",
        "burn": "burn",
        "extraTop": true,
      }, null),
      React.createElement(DealInformation, {
        "class": "_7be3643b",
        "name": "Deal Information",
        "existingDebt": "100000",
        "targetDebt": "100000",
        "useOfFunds": "Hustling",
        "extraSpace": true,
      }, null)
    )
  )
}));
export { NewMatch };


