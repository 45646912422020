import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useDispatch } from 'react-redux';
import { Routes } from '@hum/icm-app/src/state';
import { useHistory } from 'react-router';

export const useButtonGroup = ({
  companyId,
  matchUuid,
}: {
  companyId: number;
  matchUuid: string;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const api = useAPI();
  const investorMatchHandler = async (investorInterested: boolean) => {
    if (investorInterested) {
      const res = await api.syndication.sendInvestorAccept(dispatch)(
        companyId,
        matchUuid
      );
      if (!res.error) {
        history.push(
          `${Routes.SYNDICATION_INVESTOR_MATCH_INTERESTED}?uuid=${matchUuid}`
        );
      }
    } else {
      history.push(
        `${Routes.SYNDICATION_INVESTOR_MATCH_FEEDBACK}?uuid=${matchUuid}`
      );
    }
  };
  return {
    investorMatchHandler,
  };
};
