import React from 'react';
import { getUserCompanyId } from '@hum/icm-app/src/state';
import { LegalAgreements } from '@hum/icm-app/src/pages/syndication-v2/Matches/LegalAgreements';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { CompanyDetails } from './CompanyDetails';
import { Spinner } from '@hum/ui-library';

export const CompanyDetailPage = () => {
  const { state } = useAppStore();
  const companyId = getUserCompanyId(state);
  const matchUuid = state.history?.query.uuid;

  return (
    <>
      <Topbar fixedBar />
      <LegalAgreements />
      {!companyId || !matchUuid ? (
        <Spinner fullScreen />
      ) : (
        <CompanyDetails companyId={companyId} matchUuid={matchUuid} />
      )}
    </>
  );
};
