import { useSyndicationMatchDataroom } from '@hum/icm-app/src/hooks/useSyndicationMatchDataroom';
import { useDownloadFile } from '@hum/icm-app/src/__DEPRECATED__modules/files/hooks/useFileManager/download';

export const useCompanyDataRoomFiles = ({
  companyId,
}: {
  companyId?: number;
}) => {
  const { data, loaded } = useSyndicationMatchDataroom(companyId);
  const { download } = useDownloadFile(companyId);

  const downloadAllFiles = async () => {
    const fileIdsToDownload = data?.map((file) => file.id) || [];

    await Promise.all(fileIdsToDownload.map((fileId) => download(fileId)));
  };

  const handleDownloadAllFilesClick = () => {
    downloadAllFiles();
  };

  return {
    files: data,
    loaded,
    handleDownloadAllFilesClick,
  };
};
