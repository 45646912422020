import { fork } from 'redux-saga/effects';
import {
  handleDownloadCompanyFinancial,
  handleDownloadCompanyPublicFinances,
  handleDownloadMatchCompanyPublicFinances,
  handleShowCompanyFinancialSummary,
  handleCompanyTeaser,
  handleShowCompanyQuickbooksRawData,
} from './companyDetail';

export function* handleCompanyDetail() {
  yield fork(handleDownloadCompanyFinancial);
  yield fork(handleShowCompanyFinancialSummary);
  yield fork(handleShowCompanyQuickbooksRawData);
  yield fork(handleDownloadCompanyPublicFinances);
  yield fork(handleDownloadMatchCompanyPublicFinances);
  yield fork(handleCompanyTeaser);
}
