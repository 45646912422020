import React from 'react';
import {
  CompanyFileDocumentType,
  ConnectorType,
  ConnectorSource,
} from '@hum/types';
import {
  apiInvestorDownloadCompanyPublicFinancesButtonClicked,
  downloadCompanyFinancialButtonClicked,
} from '../CompanyDetail/ducks/actions';
import { useDispatch } from 'react-redux';
import { renderDate } from './File';
import {
  Button,
  ButtonType,
  Icon,
  ProgressDownload,
  Text,
  TextType,
} from '@hum/ui-library';
import { useDownloadFinancialDocuments } from '@hum/icm-app/src/hooks/useDownloadFinancialDocuments';

export type FinancialModelFileProps = {
  companyId: number;
  docType: CompanyFileDocumentType;
  docTitle: string;
  lastSuccessPullAt?: string;
  isDownloadingFile: boolean;
  connectorType?: ConnectorType;
  connectorSource?: ConnectorSource;
};

export const FinancialModelFile = ({
  companyId,
  docType,
  docTitle,
  lastSuccessPullAt,
  isDownloadingFile,
  connectorType,
  connectorSource,
}: FinancialModelFileProps) => {
  const dispatch = useDispatch();
  const date = lastSuccessPullAt ? new Date(lastSuccessPullAt) : new Date();
  const isChartbook =
    docType === CompanyFileDocumentType.ChartbookInvestorAnalytics;

  const handleDownloadFinancialModelFileClick = () => {
    // trigger a tracking event when the button is clicked
    // event: investor-dataroom-download-company-financials-button-clicked
    // see report here: https://heapanalytics.com/app/env/4084418539/graph/charts/investor-dataroom-download-company-financials-button-clicked-DOCTYPE-2678731
    dispatch(
      apiInvestorDownloadCompanyPublicFinancesButtonClicked({
        companyId: companyId,
        docType: docType,
        companyCreatorName: 'Hum Capital',
      })
    );
    // trigger the actual download
    // when the download is successful, the following event will be tracked
    // success event: investor-company-overview-download-company-financials
    dispatch(
      downloadCompanyFinancialButtonClicked({
        companyId: companyId,
        docType: docType,
        companyCreatorName: 'Hum Capital',
      })
    );
  };

  const {
    handleDownloadFinancialDocuments,
    polling,
  } = useDownloadFinancialDocuments({
    companyId,
    docType,
    connectorType,
    connectorSource,
  });

  return (
    <div>
      <li className="flex items-center p-4 select-none hover:bg-gray-700">
        <Icon.DefaultFile className="mr-4" />
        <Text
          type={TextType.UI_ELEMENT}
          className="flex-1 w-[200px] xl:w-full truncate"
          forceCapitalization={false}
        >
          {docTitle}
        </Text>
        <Text
          type={TextType.BODY_MICO}
          subdued
          className="hidden mr-6 xl:block"
        >
          Hum Capital
        </Text>
        <Text
          type={TextType.BODY_MICO}
          subdued
          className="hidden mr-6 xl:block"
        >
          {renderDate(date.toString())}
        </Text>
        <Button
          type={ButtonType.QUIET}
          testId={`dataroom:financial-model-file-${docType}`}
          disabled={!isChartbook ? polling : isDownloadingFile}
          onClick={
            !isChartbook
              ? handleDownloadFinancialDocuments
              : handleDownloadFinancialModelFileClick
          }
        >
          <Icon.Download className="mr-2" />
        </Button>
      </li>
      {(polling || isDownloadingFile) && (
        <ProgressDownload
          polling={!isChartbook ? polling : isDownloadingFile}
          text="Gathering data. This process should only take a couple of minutes. Please wait..."
        />
      )}
    </div>
  );
};
