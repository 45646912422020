import { AppState } from '../state';
import { Action, CompanyActivationActionType } from '../actions';
import produce from 'immer';

export const companyActivationReducer = (
  appState: AppState,
  action: Action
) => {
  switch (action.type) {
    case CompanyActivationActionType.GET_STARTED_DISMISSABLE: {
      return produce(appState, (newState) => {
        newState.getStartedDismissable = true;
      });
    }
    case CompanyActivationActionType.GET_STARTED_DISMISS: {
      return produce(appState, (newState) => {
        newState.getStartedDismissed = true;
      });
    }
    default: {
      return appState;
    }
  }
};
