import React, { useEffect } from 'react';
import { useSyndicationMatch } from '@hum/icm-app/src/hooks/syndication';
import { Spinner } from '@hum/ui-library';
import { SyndicationMatchStatus } from '@hum/icm-app/src/state';
import { CompanyTabsView, DealDetail } from '.';

export const CompanyDetails = ({
  companyId,
  matchUuid,
}: {
  companyId: number;
  matchUuid: string;
}) => {
  const { data: matchData } = useSyndicationMatch({
    companyId,
    uuid: matchUuid,
  });
  const shouldRedirectToV2 =
    matchData && matchData?.status !== SyndicationMatchStatus.Matched;

  useEffect(() => {
    if (shouldRedirectToV2) {
      window.location.assign(
        //eslint-disable-next-line
        `${process.env.V2_BASE_URL}/match?token=${matchUuid}`
      );
    }
  }, [shouldRedirectToV2]);

  if (!matchData || shouldRedirectToV2) {
    return <Spinner fullScreen />;
  }

  if (matchData.status === SyndicationMatchStatus.Matched) {
    return (
      <CompanyTabsView
        companyId={companyId}
        matchData={matchData}
        matchUuid={matchUuid}
      />
    );
  }

  return (
    <DealDetail
      companyId={companyId}
      matchUuid={matchUuid}
      matchData={matchData}
    />
  );
};
