import { Company, REVENUE_MODEL, REVENUE_MODEL_V6 } from '@hum/types';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { getLocation } from '@hum/icm-app/src/pages/syndication-v2/syndicationUtils';
import {
  SyndicationMatchProfile2,
  SyndicationMatchStatus,
} from '@hum/icm-app/src/state';
import {
  BUSINESS_TYPES,
  EQUITY_RAISED,
  FUNDRAISING_TIME_FRAME_MONTHS,
} from '@hum/icm-app/src/components/constants';
import { formatDate, money } from '@hum/icm-app/src/utils';
import { getGrowthRate } from '../../companyPublicDetail';
import { DealDetail } from '@hum/types/src';

export const useDealDetail = (
  matchData: SyndicationMatchProfile2
): DealDetail => {
  const NOT_AVAILABLE_LABEL = 'N/A';
  const isNewDeal = matchData.status == SyndicationMatchStatus.Sent;
  const flags = useFlags();
  const showTeaserAccuracy = flags.enabled('show-teaser-accuracy');
  const showChartBook = flags.enabled('show-chartbook-match-public-link');
  const {
    company: { application, publicDescription, teaserData },
  } = matchData! as { company: Company };
  const companyPublicDescription = publicDescription || '';
  const industryVertical =
    BUSINESS_TYPES[application.industryVertical!] || undefined;
  const foundedYear = teaserData.yearFounded || undefined;
  const location =
    getLocation(matchData?.company?.state, matchData?.company?.country) ||
    undefined;
  const targetDebt = money(application.investmentAmount);
  const revenueModel =
    REVENUE_MODEL_V6[application.revenueModel!] ||
    REVENUE_MODEL[application.revenueModel!] ||
    NOT_AVAILABLE_LABEL;
  const timeFrame =
    FUNDRAISING_TIME_FRAME_MONTHS[application.fundraisingTimeFrame];
  const revenue = money(teaserData.revenue) || undefined;
  const revenueAsOf =
    formatDate(teaserData.revenueAsOf, {
      asOfTime: true,
      dateFormat: 'YYYY-MM-DD',
    }) || undefined;
  const yoyRevenueGrowthRate =
    (teaserData.yoyRevenueGrowthRate &&
      `${teaserData.yoyRevenueGrowthRate}%`) ||
    undefined;
  const yoyRevenueGrowthRateAsOf = formatDate(
    teaserData.yoyRevenueGrowthRateAsOf as string,
    {
      asOfTime: true,
      dateFormat: 'YYYY-MM-DD',
    }
  );
  const yoyRevenueGrowthRateLabel = 'YoY Revenue Growth';
  const { growthRate, growthRateAsOf, growthRateLabel } = getGrowthRate(
    teaserData
  );
  const growthRateValue = showTeaserAccuracy
    ? growthRate
    : yoyRevenueGrowthRate;
  const growthRateAsOfValue = showTeaserAccuracy
    ? growthRateAsOf
    : yoyRevenueGrowthRateAsOf;
  const growthRateLabelValue = showTeaserAccuracy
    ? growthRateLabel
    : yoyRevenueGrowthRateLabel;
  const margins = (teaserData.margins && `${teaserData.margins}%`) || undefined;
  const marginsAsOf =
    formatDate(teaserData.marginsAsOf, {
      asOfTime: true,
      dateFormat: 'YYYY-MM-DD',
    }) || undefined;
  const ltvCac = teaserData.ltvCac?.toString() || undefined;
  const valuation = money(application.latestPostMoneyValuation) || undefined;
  const equityRaise =
    application.totalEquityRaised &&
    application.totalEquityRaised !== 'bootstrapped'
      ? EQUITY_RAISED[application.totalEquityRaised]
      : undefined;
  const runRateRevenue =
    (showTeaserAccuracy && money(teaserData.runRateRevenue)) || undefined;
  const runRateRevenueAsOf =
    (showTeaserAccuracy &&
      formatDate(teaserData.runRateRevenueAsOf as string, {
        asOfTime: true,
        dateFormat: 'YYYY-MM-DD',
      })) ||
    undefined;
  let headingText = industryVertical
    ? `${industryVertical} business`
    : 'A company';
  headingText += location ? ` based in ${location}` : '';
  headingText += ` is looking to raise ${targetDebt}`;
  headingText += timeFrame ? ` in ${timeFrame}` : '';

  return {
    showChartBook,
    isNewDeal,
    headingText,
    companyPublicDescription,
    foundedYear,
    location,
    targetDebt,
    revenueModel,
    timeFrame,
    revenue,
    revenueAsOf,
    growthRateValue,
    growthRateLabelValue,
    growthRateAsOfValue,
    margins,
    marginsAsOf,
    ltvCac,
    valuation,
    equityRaise,
    runRateRevenue,
    runRateRevenueAsOf,
  };
};
