import React from "react";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pub824B8933, {Box as _pub824B8933_Box} from "../../../../../../design-system/src/layout.pc";
import _pub2Dbb4493 from "../../../../../../design-system/src/Button.pc";
import _pubDf2A042 from "../../../../../../design-system/src/Field.pc";
import _pub14733275 from "../../../../../../design-system/src/Checkbox.pc";
import _pubC530B6D5, {ModalPreview as _pubC530B6D5_ModalPreview} from "../../../../../../design-system/src/Modal.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var ModalContent = React.memo(React.forwardRef(function ModalContent(props, ref) {
  return React.createElement(_pub824B8933_Box, {
    "class": "_21d03284 _12861cef _pub-12861cef _pub-a97d53f3",
    "ref": ref,
    "vertical": true,
    "gap": "16px",
  }, 
    React.createElement("img", {
      "className": "_a7156535 _12861cef _pub-12861cef _pub-a97d53f3",
      "src": getDefault(require("@hum/assets/icons/logo-hum-primary.svg")),
    }, null),
    React.createElement("img", {
      "className": "_3e1c348f _12861cef _pub-12861cef _pub-a97d53f3",
      "src": getDefault(require("@hum/assets/logo.svg")),
    }, null),
    React.createElement("div", {
      "className": "_491b0419 _12861cef _pub-12861cef _pub-a97d53f3" + " " + "_12861cef_text-heading-small _pub-12861cef_text-heading-small _pub-a97d53f3_text-heading-small text-heading-small",
    }, 
      "\n    You need to accept the following before continuing\n  "
      
    ),
    props["children"],
    React.createElement("div", {
      "className": "_a078a12c _12861cef _pub-12861cef _pub-a97d53f3",
    }, 
      props["footer"]
    )
  )
}));
export { ModalContent };


