import React, { memo } from 'react';
import { CompanyDataRoomSection } from '@hum/types';
import { useCompanyDataRoomFiles } from './hooks/useCompanyDataRoomFiles';
import { DownloadModelFile } from './DownloadModelFile';
import { DownloadSection } from './DownloadSection';
import { Icon, Spinner, Splash, Text, TextType } from '@hum/ui-library';

type DownloadDocumentsProps = {
  companyId: number;
  sections: CompanyDataRoomSection[];
  companyName: string;
  hasChartbook?: boolean;
};

export const CompanyDataRoom = memo(
  ({
    sections,
    companyId,
    companyName,
    hasChartbook,
  }: DownloadDocumentsProps) => {
    const { files, loaded } = useCompanyDataRoomFiles({ companyId });
    if (!loaded) return <Spinner fullScreen />;

    return (
      <>
        {hasChartbook && (
          <div className="mb-10">
            <Text type={TextType.HEADING_L}>Hum standardized data</Text>
            <Text type={TextType.BODY_M} subdued className="mt-1 mb-8">
              Documents that Hum automatically creates based on {companyName}’s
              connections and uploaded files.
            </Text>
            <DownloadModelFile companyId={companyId} />
          </div>
        )}
        {files && files?.length > 0 && (
          <div className="mb-2">
            <Text type={TextType.HEADING_L}>Company uploaded documents</Text>
            <Text type={TextType.BODY_M} subdued className="mt-1 mb-8">
              Documents formatted and uploaded by {companyName}.
            </Text>
            {sections.map((section) => (
              <DownloadSection
                key={section.label}
                section={section}
                files={files || []}
              />
            ))}
          </div>
        )}
        {(!files || files?.length === 0) && !hasChartbook && (
          <div className="flex flex-col items-center justify-center h-[70vh]">
            <Splash
              title="Nothing here yet"
              subtitle={`We’ll notify you when ${companyName} uploads new information`}
              graphic={<Icon.GrphImage className="max-w-[250px] mx-auto" />}
            />
          </div>
        )}
      </>
    );
  }
);
