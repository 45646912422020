import produce from 'immer';
import OnboardingV5Reducers from '@hum/icm-app/src/pages/onboarding-v5/ducks/reducers';
import { confettiReducer } from '@hum/icm-app/src/modules/confetti/ducks';
import { analyticsReducer } from '@hum/icm-app/src/modules/analytics/ducks';
import { MNDAActions } from '@hum/icm-app/src/pages/onboarding-v5/components/MNDA/ducks/actions';
import { companyDetailReducer } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/reducers';
import { Action, ActionType } from '../actions';
import { AppState, INITIAL_APP_STATE, AnalysisStatus } from '../state';
import { docusignReducer } from './docusign';
import { syndicationReducer } from './syndication';
import { authReducer } from './auth';
import { onboardingReducer } from './onboarding';
import { companyActivationReducer } from './company-activation';
import { principalInvestmentReducer } from './principal-investment';
import { portfolioMonitoringReducer } from './portfolio-monitoring';
import { adminReducer } from './admin';
import { adminAnalyticsReducer } from '@hum/icm-app/src/pages/admin/AdminAnalytics/ducks/reducers';
import { creditBoxReducer } from '@hum/icm-app/src/pages/admin/tabs/Syndication/CompanySyndication/ducks/reducers';

export function appReducer(
  state: AppState = INITIAL_APP_STATE,
  action: Action
): AppState {
  switch (action.type) {
    case ActionType.API_INVESTOR_AVAILABLE_COMPANIES_LOADED: {
      return produce(state, (newState) => {
        newState.investorAvailableCompanies = action.payload.companies;
      });
    }
    default: {
      state = requestReducer(state, action);
      state = devReducer(state, action);
      state = docusignReducer(state, action);
      state = syndicationReducer(state, action);
      state = authReducer(state, action);
      state = onboardingReducer(state, action);
      state = companyActivationReducer(state, action);
      state = principalInvestmentReducer(state, action);
      state = portfolioMonitoringReducer(state, action);
      state = pagesReducer(state, action);
      state = modulesReducer(state, action);
      state = adminReducer(state, action);
      state = companyDetailReducer(state, action);
      state = adminAnalyticsReducer(state, action);
      state = creditBoxReducer(state, action);
      return state;
    }
  }
}

const pagesReducer = (state: AppState, action: Action) => {
  return {
    ...state,
    ...OnboardingV5Reducers(state, action as MNDAActions),
  };
};

const modulesReducer = (state: AppState, action: Action) => {
  state = confettiReducer(state, action);
  state = analyticsReducer(state, action);
  return state;
};

function requestReducer(state: AppState, action: Action) {
  switch (action.type) {
    case ActionType.API_COMPANIES_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        // Prevent undefined companies and pagination when loading state changes
        Object.assign(newState.companies, action.payload.result);
      });
    }
    case ActionType.CHARTS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.charts = action.payload.result;
      });
    }
    case ActionType.CONNECTORS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.connectors, action.payload.result);
      });
    }
    case ActionType.BRANDING_EDITOR_CHANGED: {
      return produce(state, (newState) => {
        newState.branding.data = {
          ...(newState.branding.data || {}),
          ...action.payload.branding,
        };
      });
    }
    case ActionType.DOCUSIGN_ENVELOPE_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.envelope, action.payload.result, {
          // eehhh, not pretty, but trying to keep loaded state after it's flagged as true
          loaded: newState.envelope.loaded || action.payload.result.loaded,
        });
      });
    }
    case ActionType.COMPANY_DATA_ASSETS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.currentCompanyDataAssets = action.payload.result;
      });
    }
    case ActionType.COMPANY_CONTACT_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.currentCompanyUser = action.payload.result;
      });
    }
    case ActionType.COMPANY_FILES_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.currentCompanyFiles, action.payload.result);
      });
    }
    case ActionType.COMPANY_REQUEST_STATE_CHANGED: {
      // TODO: research where this is used
      // if it is triggered when the company.id changes, make sure to:
      // - reset companyUsers to empty array or initial result
      return produce(state, (newState) => {
        Object.assign(newState.currentCompany, action.payload.result);
        if (
          state.currentCompany.data?.id === newState.currentCompany.data?.id
        ) {
          return;
        }

        newState.syndicationMatches.data = [];
        newState.syndicationRecommendations.data = [];
        newState.companyAnalyses.data = [];
        newState.companyAnalysisWarnings.data = [];
        newState.selectedCompanyAnalysisId = undefined;
      });
    }
    case ActionType.COMPANY_SETTINGS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.currentCompanySettings, action.payload.result);
      });
    }
    case ActionType.API_INVESTOR_COMPANIES_LOADED: {
      return produce(state, (newState) => {
        newState.investorCompanies = action.payload.companies;
      });
    }
    case ActionType.ADMIN_COMPANY_INVESTOR_LIST_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.companyInvestorList = action.payload.result;
      });
    }
    case ActionType.COMPANY_INVITATIONS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.currentCompanyInvitations = action.payload.result;
      });
    }
    case ActionType.API_UPDATE_BRANDING_REQUEST_STATE_CHANGED:
    case ActionType.API_BRANDING_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.branding, action.payload.result);
      });
    }
    case ActionType.WHITELABEL_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.whitelabeling, action.payload.result);
      });
    }
    case ActionType.COMPANY_USERS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.currentCompanyUsers, action.payload.result);
      });
    }
    case ActionType.COMPANY_LIST_FILTERS_CHANGED: {
      return produce(state, (newState) => {
        newState.companyListFilters = action.payload.filters;
      });
    }

    case ActionType.AVAILABLE_CONNECTORS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.availableConnectors, action.payload.result);
      });
    }

    case ActionType.COMPANY_PUBLIC_PROFILE_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.companyPublicProfile, action.payload.result);
      });
    }
    case ActionType.COMPANY_ANALYSES_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.companyAnalyses, action.payload.result);

        if (
          newState.selectedCompanyAnalysisId ||
          !newState.companyAnalyses.data?.length
        ) {
          return;
        }
        if (newState.currentCompany.data?.frozenCompanyAnalysisId) {
          newState.selectedCompanyAnalysisId =
            newState.currentCompany.data.frozenCompanyAnalysisId;
          return;
        }

        const companyAnalysis = newState.companyAnalyses.data.find(
          ({ status }) => status !== AnalysisStatus.processing
        );
        if (companyAnalysis) {
          newState.selectedCompanyAnalysisId = companyAnalysis.id;
        } else {
          newState.selectedCompanyAnalysisId =
            newState.companyAnalyses.data[0].id;
        }
      });
    }
    case ActionType.COMPANY_ANALYSIS_WARNINGS_REQUEST_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.companyAnalysisWarnings, action.payload.result);
      });
    }

    case ActionType.COMPANY_ANALYSIS_SELECTED: {
      return produce(state, (newState) => {
        newState.selectedCompanyAnalysisId =
          action.payload.selectedCompanyAnalysisId;
      });
    }

    case ActionType.FROZEN_COMPANY_ANALYSIS_STATE_CHANGED: {
      return produce(state, (newState) => {
        const { data, loaded } = action.payload.result;
        if (!loaded || !data) {
          return;
        }
        const { companyId, frozenCompanyAnalysisId } = data;

        if (!newState.currentCompany.loaded || !newState.currentCompany.data) {
          return;
        }
        const currentCompanyId = newState.currentCompany.data?.id;
        if (companyId !== currentCompanyId) {
          return;
        }

        newState.currentCompany.data.frozenCompanyAnalysisId = frozenCompanyAnalysisId;
      });
    }

    default: {
      return state;
    }
  }
}

function devReducer(state: AppState, action: Action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
