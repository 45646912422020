import { AppState } from '../state';
import { Action, OnboardingActionType } from '../actions';
import produce from 'immer';

export const onboardingReducer = (appState: AppState, action: Action) => {
  switch (action.type) {
    case OnboardingActionType.COMPANY_SIGNUP_REQUEST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.companySignUp = action.payload.result;
      });
    }
    case OnboardingActionType.API_ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.canCompanyBeAddedToInvestorSubdomainsInvestigation =
          action.payload.result;
      });
    }
    case OnboardingActionType.API_ONBOARDING_COMPANY_NOTICE_EXISTENCE_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.companyNotices = {
          ...action.payload.result,
          data: {
            ...appState.companyNotices.data,
            ...action.payload.result.data,
          },
        };
      });
    }
    case OnboardingActionType.API_ONBOARDING_COMPANY_ACTIVATION_NOTICE_EXISTENCE_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.companyActivationNotices = action.payload.result;
      });
    }
    case OnboardingActionType.API_COMPANY_TOS_BY_TOKEN_STATE_CHANGED: {
      return produce(appState, (newState) => {
        newState.tosByToken = action.payload.result;
      });
    }
    default: {
      return appState;
    }
  }
};
