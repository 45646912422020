import { AppState } from '../state';
import { Action, AdminActionType } from '../actions';
import produce from 'immer';

export const adminReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case AdminActionType.ADMIN_COMPANY_USERS_STATE_CHANGED: {
      return produce(state, (newState) => {
        newState.companyUsers = {
          ...newState.companyUsers,
          ...action.payload.result,
        };
      });
    }
    default: {
      return state;
    }
  }
};
