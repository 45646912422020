import React from 'react';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  Button,
  Spinner,
  TextType,
  Text,
  ButtonType,
  Icon,
  ProgressDownload,
} from '@hum/ui-library';
import { DOWNLOAD_SECTIONS } from '@hum/types';
import { getUserCompanyId } from '@hum/icm-app/src/state';
import { useSyndicationMatch } from '@hum/icm-app/src/hooks/syndication';
import { useBulkDownload } from '@hum/icm-app/src/hooks/useBulkDownload';
import { CompanyDataRoom } from './CompanyDataRoom';
import { useSyndicationMatchDataroom } from '@hum/icm-app/src/hooks/useSyndicationMatchDataroom';

export const MatchDataRoom = ({ matchUuid }: { matchUuid: string }) => {
  const { state } = useAppStore();
  const companyInvestorId = getUserCompanyId(state);
  let matchData = state.syndicationMatch?.data;

  if (!matchData) {
    const { data: syndicationMatchData } = useSyndicationMatch({
      companyId: companyInvestorId,
      uuid: matchUuid,
    });
    if (!syndicationMatchData) {
      return <Spinner fullScreen />;
    }
    matchData = syndicationMatchData;
  }

  // TODO confirm that the companyId sent to /dataroom should be of the match.company NOT investor company [ISSUE #6262]
  const {
    company: {
      name: companyName,
      id: companyId,
      teaserData: { revenue },
    },
  } = matchData;
  const { data: files } = useSyndicationMatchDataroom(companyId);
  const flags = useFlags();
  const {
    handleDownloadAllFilesClick,
    taskStatus,
    text,
    polling,
    cancelPolling,
  } = useBulkDownload({
    companyId: matchData?.company.id,
  });
  const hasChartbook = Boolean(revenue);
  const hasFiles = Boolean(files && files.length);

  if (!matchUuid) {
    return <Spinner fullScreen />;
  }

  return (
    <div className="w-full max-w-4xl mx-auto py-10 px-8">
      <div className="flex justify-between pb-8">
        <Text type={TextType.DISPLAY_S}>Data room</Text>
        {flags.enabled('show-dataroom-download-all-button') &&
          (hasChartbook || hasFiles) && (
            <Button
              onClick={handleDownloadAllFilesClick}
              type={ButtonType.PRIMARY}
              disabled={polling}
            >
              <Icon.Download className="mr-2" width="16" height="16" light />
              Download all
            </Button>
          )}
      </div>
      <CompanyDataRoom
        sections={DOWNLOAD_SECTIONS}
        companyId={companyId}
        companyName={companyName}
        hasChartbook={hasChartbook}
      />

      <ProgressDownload
        text={text}
        polling={polling}
        status={taskStatus?.state}
        onCancel={cancelPolling}
      />
    </div>
  );
};
