import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { noop } from 'lodash';

import { trackActions } from '@hum/icm-app/src/modules/analytics';
import { getAPI } from '@hum/icm-app/src/sagas/utils';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { API } from '@hum/icm-app/src/backend';
import {
  createDataResult,
  createLoadingResult,
  createErrorResult,
} from '@hum/common';

import {
  failedDownloadedCompanyFinancials,
  companyDetailActions,
  isDownloadingCompanyFinancialsFile,
  successfullyDownloadedCompanyFinancials,
  companyFinancialComponentStateChanged,
  CompanyDetailActionType,
  ApiGetCompanyTeaserDataRequestSent,
  apiGetCompanyTeaserDataRequestStateChanged,
  companyQuickbooksRawDataStateChanged,
} from '../actions';
import { CompanyFileDocumentType } from '@hum/types';
import { toast } from '@hum/common/src/modules/toast';

function* createDownloadLink(
  companyId: number,
  docType: CompanyFileDocumentType
) {
  const api: API = yield getAPI();
  try {
    const taskId = yield call(api.legacy.getTaskUrlCompanyFinancial, {
      companyId,
      docType,
    });
    while (true) {
      const data = yield call(api.legacy.downloadCompanyFinancialModels, {
        companyId,
        taskId,
        docType,
      });
      if (data.state === 'SUCCESS') {
        window.location.assign(data.file_url);
        yield put(
          successfullyDownloadedCompanyFinancials({ docType: docType })
        );
        break;
      } else if (data.state === 'FAILURE') {
        yield call(() =>
          toast.error(
            'Error: data mapping is still processing. Please try again later and contact us if the error persists'
          )
        );
        yield put(failedDownloadedCompanyFinancials({ docType: docType }));
        break;
      } else {
        yield put(isDownloadingCompanyFinancialsFile({ docType: docType }));
      }
      yield delay(2000);
    }
  } catch (e) {
    yield put(failedDownloadedCompanyFinancials({ docType: docType }));
    throw e;
  }
}
function* createDownloadPublicLink(companyUuid: string) {
  const api: API = yield getAPI();
  try {
    const taskId = yield call(api.legacy.getTaskIdCompanyPublicFinances, {
      companyUuid,
    });
    while (true) {
      const data = yield call(
        api.legacy.downloadCompanyPublicFinancesData,
        taskId
      );
      if (data.state === 'SUCCESS') {
        window.location.assign(data.file_url);
        yield put(
          successfullyDownloadedCompanyFinancials({
            docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
          })
        );
        break;
      } else if (data.state === 'FAILURE') {
        yield put(
          failedDownloadedCompanyFinancials({
            docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
          })
        );
        break;
      } else {
        yield put(
          isDownloadingCompanyFinancialsFile({
            docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
          })
        );
      }
      yield delay(2000);
    }
  } catch (error) {
    yield put(
      failedDownloadedCompanyFinancials({
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
      })
    );
    throw error;
  }
}
function* createDownloadMatchPublicLink(companyId: number, matchUuid: string) {
  const api: API = yield getAPI();
  try {
    const taskId = yield call(api.legacy.getTaskIdMatchCompanyPublicFinances, {
      companyId,
      matchUuid,
    });
    while (true) {
      const data = yield call(
        api.legacy.downloadCompanyPublicFinancesData,
        taskId
      );
      if (data.state === 'SUCCESS') {
        window.location.assign(data.file_url);
        yield put(
          successfullyDownloadedCompanyFinancials({
            docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
          })
        );
        break;
      } else if (data.state === 'FAILURE') {
        yield put(
          failedDownloadedCompanyFinancials({
            docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
          })
        );
        break;
      } else {
        yield put(
          isDownloadingCompanyFinancialsFile({
            docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
          })
        );
      }
      yield delay(2000);
    }
  } catch (error) {
    yield put(
      failedDownloadedCompanyFinancials({
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
      })
    );
    throw error;
  }
}

export function* showCompanyFinancialSummary(companyId: number) {
  const api: API = yield getAPI();
  try {
    const res = yield call(api.legacy.showCompanyFinancialSummary, companyId);
    yield put(companyFinancialComponentStateChanged(res));
  } catch (error) {
    throw error;
  }
}

export function* showQuickBooksRawData(companyId: number) {
  const api: API = yield getAPI();
  try {
    const res = yield call(api.legacy.showQuickBooksRawData, companyId);
    yield put(companyQuickbooksRawDataStateChanged(res));
  } catch (error) {
    throw error;
  }
}

export function* handleDownloadCompanyFinancial() {
  yield takeLatest(
    CompanyDetailActionType.DOWNLOAD_COMPANY_FINANCIAL_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyId, docType } }) {
      yield call(createDownloadLink, companyId, docType);
    }, noop)
  );
}

export function* handleDownloadCompanyPublicFinances() {
  yield takeLatest(
    CompanyDetailActionType.DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyUuid } }) {
      yield call(createDownloadPublicLink, companyUuid);
    }, noop)
  );
}

export function* handleDownloadMatchCompanyPublicFinances() {
  yield takeLatest(
    CompanyDetailActionType.DOWNLOAD_MATCH_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyId, matchUuid } }) {
      yield call(createDownloadMatchPublicLink, companyId, matchUuid);
    }, noop)
  );
}

export function* handleShowCompanyFinancialSummary() {
  yield takeLatest(
    [
      companyDetailActions.companyFinancialComponentShowed.type,
      companyDetailActions.companyFinancialSummaryVisibilityHookUsed.type,
    ],
    catchError(function* ({ payload: { companyId } }) {
      yield call(showCompanyFinancialSummary, companyId);
    }, noop)
  );
}
export function* handleShowCompanyQuickbooksRawData() {
  yield takeLatest(
    CompanyDetailActionType.COMPANY_QUICKBOOKS_RAW_DATA_COMPONENT_SHOWED,
    catchError(function* ({ payload: { companyId } }) {
      yield call(showQuickBooksRawData, companyId);
    }, noop)
  );
}

export function* handleCompanyTeaser() {
  yield takeLatest(
    [CompanyDetailActionType.GET_COMPANY_TEASER_DATA_REQUEST_SENT],
    handleGetCompanyTeaserData
  );
  yield trackActions([
    //https://www.notion.so/humcapital/investor-dataroom-download-company-financials-button-clicked-c5bc2fec25eb4a13a7d67500f4aeb2c8
    {
      actionType:
        CompanyDetailActionType.INVESTOR_DOWNLOAD_COMPANY_PUBLIC_FINANCES_BUTTON_CLICKED,
      track: ({ payload }) => ({
        name: 'investor-dataroom-download-company-financials-button-clicked',
        payload,
      }),
    },
    {
      actionType:
        CompanyDetailActionType.INVESTOR_DOWNLOAD_DATAROOM_FILE_BUTTON_CLICKED,
      track: ({ payload }) => ({
        name: 'investor-dataroom-download-file-button-clicked',
        payload,
      }),
    },
  ]);
}

export function* handleGetCompanyTeaserData({
  payload,
}: ApiGetCompanyTeaserDataRequestSent) {
  const api: API = yield getAPI();
  try {
    yield put(
      apiGetCompanyTeaserDataRequestStateChanged({
        result: createLoadingResult(),
      })
    );
    const res = yield call(api.syndication.getCompanyPublicInfo, payload.token);
    if (res) {
      yield put(
        apiGetCompanyTeaserDataRequestStateChanged({
          result: createDataResult(res),
        })
      );
    }
  } catch (error) {
    yield put(
      apiGetCompanyTeaserDataRequestStateChanged({
        result: createErrorResult(error as Error),
      })
    );
    throw error;
  }
}
