import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, AppState } from '../../state';
import confetti from '@hum/icm-app/src/utils/confetti';
import { useHistory } from 'react-router';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { Button, ButtonType, Icon, Text, TextType } from '@hum/ui-library';
import illustration from './illustration.svg';

export const MatchInterestedPage = () => {
  const history = useHistory();
  confetti();
  const state: AppState = useSelector(identity);
  const matchUuid = state.history?.query.uuid;
  return (
    <>
      <Topbar />
      <div className="w-full max-w-4xl mx-auto h-[calc(100vh-48px)]">
        <div className="h-full flex flex-col items-center justify-center">
          <div className="max-w-[448px] mx-auto bg-transparent md:bg-gray-600 md:shadow-default rounded-lozenge p-4 md:p-14">
            <Text type={TextType.BODY_M} className="text-center">
              We’re glad you’re a match. Check your inbox, you’ll get an intro
              shortly.
            </Text>
            <Icon src={illustration} className="w-full" />
            <Button
              className="mt-10 w-full"
              type={ButtonType.PRIMARY}
              onClick={() => {
                history.push(
                  `${Routes.SYNDICATION_INVESTOR_MATCH}?uuid=${matchUuid}`
                );
              }}
            >
              Go to deal
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const identity = (state: AppState) => state;
