import { AppState, Routes, getPathnameParams } from '../state';
import { HistoryAction, HistoryActionType } from '../actions';
import produce from 'immer';

export const historyReducer = (state: AppState, action: HistoryAction) => {
  switch (action.type) {
    case HistoryActionType.HISTORY_INITIALIZED:
    case HistoryActionType.HISTORY_CHANGED:
    case HistoryActionType.ROUTER_URL_PUSHED: {
      return produce(state, (newState) => {
        newState.history = Object.assign({}, newState.history, action.payload);

        newState.history.params = getPathnameParams(
          newState.history!.pathname,
          Routes
        );
      });
    }
    default: {
      return state;
    }
  }
};
