import React from 'react';
import {
  CompanyFileDocumentType,
  CompanyFileDocumentLabel,
  ConnectorTypeLabel,
} from '@hum/types';
import { useSelector } from 'react-redux';
import { FinancialModelFile } from './FinancialModelFile';
import { useQuickBooksRawDataVisibility } from '@hum/icm-app/src/hooks/useQuickBooksRawDataVisibility';
import { AppState } from '@hum/icm-app/src/state';
import { useApiQuery, getFinancialDocuments } from '@hum/api';

type DownloadModelFileProps = {
  companyId: number;
};

export const DownloadModelFile = ({ companyId }: DownloadModelFileProps) => {
  const quickbooksChecker = useQuickBooksRawDataVisibility(companyId);
  const isDownloadingFile = useSelector(
    (state: AppState) => state.isDownloadingCompanyFinancialsFile
  );
  const { data: documents, isLoading: documentsLoading } = useApiQuery(
    getFinancialDocuments,
    {
      params: {
        companyId,
      },
    }
  );
  const chartbookProps = {
    companyId,
    docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
    docTitle: 'Chartbook',
    isDownloadingFile:
      isDownloadingFile.docType ===
      CompanyFileDocumentType.ChartbookInvestorAnalytics
        ? isDownloadingFile.status
        : false,
  };

  if (!documentsLoading && documents) {
    return (
      <div className="rounded-[8px] border border-borderColor">
        {documents.financialDocuments.map((doc, index) => (
          <FinancialModelFile
            key={doc.connectorType + index}
            companyId={companyId}
            docType={doc.docType}
            lastSuccessPullAt={doc.lastSuccessPullAt}
            docTitle={`${CompanyFileDocumentLabel[doc.docType]}_${
              ConnectorTypeLabel[doc.connectorType]
            }`}
            connectorType={doc.connectorType}
            connectorSource={doc.connectorSource}
            isDownloadingFile={
              isDownloadingFile.docType === doc.docType
                ? isDownloadingFile.status
                : false
            }
          />
        ))}
        <FinancialModelFile {...chartbookProps} />
      </div>
    );
  }

  return (
    <div className="rounded-[8px] border border-borderColor">
      {quickbooksChecker && (
        <>
          <FinancialModelFile
            companyId={companyId}
            docType={CompanyFileDocumentType.IncomeStatement}
            docTitle={'IncomeStatement_QuickBooks'}
            isDownloadingFile={
              isDownloadingFile.docType ===
              CompanyFileDocumentType.IncomeStatement
                ? isDownloadingFile.status
                : false
            }
          />
          <FinancialModelFile
            companyId={companyId}
            docType={CompanyFileDocumentType.BalanceSheet}
            docTitle={'BalanceSheet_QuickBooks'}
            isDownloadingFile={
              isDownloadingFile.docType === CompanyFileDocumentType.BalanceSheet
                ? isDownloadingFile.status
                : false
            }
          />
        </>
      )}
      <FinancialModelFile {...chartbookProps} />
    </div>
  );
};
