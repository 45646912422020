import { AppState } from '../state';
import { Action, PrincipalInvestmentActionType } from '../actions';
import produce from 'immer';

export const principalInvestmentReducer = (
  appState: AppState,
  action: Action
) => {
  switch (action.type) {
    case PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENTS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.principalInvestments, action.payload.result);
      });
    }
    case PrincipalInvestmentActionType.API_AVAILABLE_BALANCE_SHEET_PARTNERS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.principalInvestmentAvailableBalanceSheetPartners,
          action.payload.result
        );
      });
    }
    default: {
      return appState;
    }
  }
};
