import React, { useState } from 'react';
import clsx from 'clsx';
import { SyndicationMatchProfile2 } from '@hum/icm-app/src/state';
import { useUrlQuery } from '@hum/common/src/hooks/useUrlQuery';
import { Company } from '@hum/types';
import { SidebarNav, TextType, Text, Icon } from '@hum/ui-library';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useCompanyTabsView } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail';
import { MatchDataRoom } from '../MatchDataRoom';
import { MatchInsights } from '../MatchInsights';
import { TransactionDocuments } from '.';
import { CompanyOverviewV2 } from './CompanyOverviewV2';

export enum TABS {
  OVERVIEW = 'overview',
  DATAROOM = 'dataRoom',
  TRANSACTION_DOCUMENTS = 'transactionDocuments',
  INSIGHTS = 'insights',
}

export enum INSIGHTS_TABS {
  FINANCIAL_ANALYSIS = 'financial_analysis',
  COHORT_ANALYSIS = 'cohort_analysis',
  CUSTOMER_ANALYSIS = 'customer_analysis',
}

export enum INSIGHTS_TABS_V2 {
  FINANCIAL_ANALYSIS = 'financialAnalysis',
  COHORT_ANALYSIS = 'cohortAnalysis',
  CUSTOMER_ANALYSIS = 'customerAnalysis',
}

export const CHART_TAB_TITLE_MAP = {
  [INSIGHTS_TABS.FINANCIAL_ANALYSIS]: 'Financial Analysis',
  [INSIGHTS_TABS.CUSTOMER_ANALYSIS]: 'Revenue Analysis',
  [INSIGHTS_TABS.COHORT_ANALYSIS]: 'Cohort Analysis',
};

export const CompanyTabsView = ({
  matchData,
  matchUuid,
  companyId,
}: {
  matchData: SyndicationMatchProfile2;
  matchUuid: string;
  companyId: number;
}) => {
  const [{ activeTab = TABS.OVERVIEW }, updateQuery] = useUrlQuery();
  const setActiveTab = (activeTab: string) => updateQuery({ activeTab });
  const [activeInsightsTab, setActiveInsightsTab] = useState(
    INSIGHTS_TABS.FINANCIAL_ANALYSIS
  );

  const { company } = matchData! as { company: Company };
  const { name, id: targetCompanyId } = company;
  const {
    isDownloadingCompanyFinancialsFile,
    clickDownloadButton,
    showCompanyFinancialSummary,
  } = useCompanyTabsView(companyId, targetCompanyId);

  const renderTab = (activeTab: string) => {
    switch (activeTab) {
      case TABS.OVERVIEW:
        return (
          <CompanyOverviewV2
            matchData={matchData}
            clickDownloadButton={() => clickDownloadButton()}
            goToDataRoomTab={() => {
              setActiveTab(TABS.DATAROOM);
            }}
            isDownloadingFile={isDownloadingCompanyFinancialsFile.status}
            showHumEnhancedFilesInvestor={showCompanyFinancialSummary}
          />
        );
      case TABS.DATAROOM:
        return <MatchDataRoom matchUuid={matchUuid} />;
      case TABS.INSIGHTS:
        return (
          <MatchInsights
            matchUuid={matchUuid}
            insightsChartsTab={activeInsightsTab}
          />
        );
      case TABS.TRANSACTION_DOCUMENTS:
        return (
          <TransactionDocuments
            matchUuid={matchUuid}
            companyId={companyId}
            targetCompanyId={targetCompanyId}
            companyName={name}
          />
        );

      default:
        return;
    }
  };

  return (
    <>
      <SidebarNav fixedBar>
        <SidebarNav.Item className="cursor-default hover:bg-transparent">
          <div className="flex items-center">
            <Icon.Building className="mr-3 flex-0" />
            <Text type={TextType.HEADING_S}>{company.name}</Text>
          </div>
        </SidebarNav.Item>
        <SidebarNav.Item
          active={activeTab === TABS.OVERVIEW}
          onClick={() => {
            setActiveTab(TABS.OVERVIEW);
          }}
          testId="syndication:investor-live-deals-overview-tab"
        >
          <Text type={TextType.UI_ELEMENT}>Overview</Text>
        </SidebarNav.Item>
        <SidebarNav.Item
          active={activeTab === TABS.DATAROOM}
          onClick={() => {
            setActiveTab(TABS.DATAROOM);
          }}
          testId="syndication:investor-live-deals-dataroom-tab"
        >
          <Text type={TextType.UI_ELEMENT}>Data room</Text>
        </SidebarNav.Item>
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button
                as={SidebarNav.Item}
                testId="syndication:investor-live-deals-insights-tab"
                active={activeTab === TABS.INSIGHTS}
                onClick={() => {
                  setActiveTab(TABS.INSIGHTS);
                }}
              >
                <Text type={TextType.UI_ELEMENT}>Insights</Text>
                <ChevronRightIcon
                  className={clsx(
                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                    'h-5 w-5 shrink-0'
                  )}
                />
              </Disclosure.Button>
              <Disclosure.Panel as="div" className="flex flex-col mt-3 gap-y-3">
                <SidebarNav.Item
                  testId="syndication:investor-live-deals-insights-tab:financial-analysis-sub-tab"
                  active={
                    activeInsightsTab === INSIGHTS_TABS.FINANCIAL_ANALYSIS
                  }
                  onClick={() => {
                    setActiveInsightsTab(INSIGHTS_TABS.FINANCIAL_ANALYSIS);
                    setActiveTab(TABS.INSIGHTS);
                  }}
                >
                  <Text type={TextType.UI_ELEMENT}>Financial Analysis</Text>
                </SidebarNav.Item>
                <SidebarNav.Item
                  testId="syndication:investor-live-deals-insights-tab:customer-analysis-sub-tab"
                  active={activeInsightsTab === INSIGHTS_TABS.CUSTOMER_ANALYSIS}
                  onClick={() => {
                    setActiveInsightsTab(INSIGHTS_TABS.CUSTOMER_ANALYSIS);
                    setActiveTab(TABS.INSIGHTS);
                  }}
                >
                  <Text type={TextType.UI_ELEMENT}>Revenue Analysis</Text>
                </SidebarNav.Item>
                <SidebarNav.Item
                  testId="syndication:investor-live-deals-insights-tab:cohort-analysis-sub-tab"
                  active={activeInsightsTab === INSIGHTS_TABS.COHORT_ANALYSIS}
                  onClick={() => {
                    setActiveInsightsTab(INSIGHTS_TABS.COHORT_ANALYSIS);
                    setActiveTab(TABS.INSIGHTS);
                  }}
                >
                  <Text type={TextType.UI_ELEMENT}>Cohort Analysis</Text>
                </SidebarNav.Item>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </SidebarNav>
      <SidebarNav.Content>{renderTab(activeTab)}</SidebarNav.Content>
    </>
  );
};
