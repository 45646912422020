import React from "react";
import _pub8Ac8C6B9, {Preview as _pub8Ac8C6B9_Preview} from "../../components/Topbar/index.pc";
import _pub7Ab5Bb97, {CenterCard as _pub7Ab5Bb97_CenterCard,Container as _pub7Ab5Bb97_Container} from "./base.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import "./interested.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Interested = React.memo(React.forwardRef(function Interested(props, ref) {
  return React.createElement("div", {
    "className": "_d7fe5190 _628b3c81 _pub-628b3c81",
    "ref": ref,
  }, 
    React.createElement(_pub7Ab5Bb97_CenterCard, {
      "class": "_5d0074ac _628b3c81 _pub-628b3c81",
    }, 
      React.createElement("div", {
        "className": "_65f753ea _628b3c81 _pub-628b3c81",
      }, 
        "\n      We’re glad you’re a match. Check your inbox, you’ll get an intro shortly.\n    "
        
      ),
      React.createElement("img", {
        "className": "_fcfe0250 _628b3c81 _pub-628b3c81",
        "src": getDefault(require("./illustration.svg")),
      }, null),
      React.createElement("div", {
        "className": "_8bf932c6 _628b3c81 _pub-628b3c81",
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_655ae29b _628b3c81 _pub-628b3c81",
          "v3": true,
          "wide": true,
          "primary": true,
          "onClick": props["onKeepBrowsingCompanies"],
        }, 
          "\n        Go to deal\n      "
          
        )
      )
    )
  )
}));
export { Interested };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_39f030bc _628b3c81 _pub-628b3c81" + (props["humDark"] ? " " + "_628b3c81_hum _pub-628b3c81_hum hum" + " " + " dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9_Preview, {
      "class": "_29839054 _628b3c81 _pub-628b3c81",
      "investor": true,
    }, null),
    React.createElement(Interested, {
      "class": "_5e84a0c2",
    }, null)
  )
}));
export { Preview };


