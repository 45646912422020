import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CompanyType } from '@hum/types';
import { Checkbox, Field, TextInput } from '@hum/legacy-ui';
import { Button } from '@hum/ui-library';
import { analytics, env } from '@hum/common';
import { useSignupForm } from '@hum/icm-app/src/pages/auth/hooks/useSignupForm';
import { getLoginRedirectPath } from '@hum/icm-app/src/state';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useBreakpoints } from '@hum/common/src/hooks/useBreakpoints';

import {
  SignUpCompleted,
  RequestAccess,
  TopBarIntro,
  AccessButtons,
} from './PublicPageSignUp.pc';

const SignUpForm = () => {
  const [completedSignup, setCompletedSignup] = useState(false);
  const { form, fields } = useSignupForm({
    onSuccess: () => setCompletedSignup(true),
    companyRoles: [CompanyType.SyndicationInvestor],
  });

  if (completedSignup) {
    return <SignUpCompleted />;
  }

  return (
    <RequestAccess>
      <form onSubmit={form.handleSubmit}>
        <Field label="Firm's legal entity name" {...fields.company}>
          {(props) => (
            <TextInput autoFocus data-testid="company-input" {...props} />
          )}
        </Field>
        <Field label="Email" {...fields.email}>
          {(props) => <TextInput data-testid="email-input" {...props} />}
        </Field>
        <Field label="First Name" {...fields.firstName}>
          {(props) => <TextInput data-testid="firstname-input" {...props} />}
        </Field>
        <Field label="Last Name" {...fields.lastName}>
          {(props) => <TextInput data-testid="lastname-input" {...props} />}
        </Field>

        <Field
          toggle
          v3
          {...fields.acceptedTOS}
          data-testid="company_signup:accept_tos_checkbox"
          className="space-bottom"
          label={
            <>
              I accept Hum Capital's{' '}
              <a
                href={`https://${env.CAPITAL_MAIN_DOMAIN}/terms-of-service`}
                data-testid="company_signup:link:accept_tos"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  // @ts-ignore
                  analytics.track('signup:view-tos');
                  // because <a> is inside this button, if user clicks view ToS prevent checkbox from turning on
                  e.stopPropagation();
                }}
              >
                terms of service
              </a>
            </>
          }
        >
          {(props) => <Checkbox {...props} />}
        </Field>
        <Button fullWidth submit>
          Request access
        </Button>
      </form>
    </RequestAccess>
  );
};

// Show a login/signup if the user is not logged in
export const PublicPageSignUp = () => {
  const { state } = useAppStore();
  const { medium } = useBreakpoints();
  const history = useHistory();
  const currentUser = state.session.data;
  const [showSignUp, setShowSignUp] = useState(false);

  if (currentUser?.id) {
    return null;
  }

  if (showSignUp) {
    return <SignUpForm />;
  }

  return (
    <TopBarIntro>
      <AccessButtons
        smaller={medium}
        toggleShowRegister={() => setShowSignUp(true)}
        clickInGoToLogin={() => {
          history.push(getLoginRedirectPath(window.location.pathname));
        }}
      />
    </TopBarIntro>
  );
};
