import { AppState } from '../state';
import { Action, PortfolioMonitoringActionType } from '../actions';
import produce from 'immer';

export const portfolioMonitoringReducer = (
  appState: AppState,
  action: Action
) => {
  switch (action.type) {
    case PortfolioMonitoringActionType.PM_INVITED_COMPANIES_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.investorPortfolioInvites, action.payload.result);
      });
    }
    case PortfolioMonitoringActionType.PM_COMPANY_INVITE_LIST_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.companyPortfolioInvites, action.payload.result);
      });
    }
    case PortfolioMonitoringActionType.PM_SHOW_ONBOARDING_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.showPortfolioOnboarding, action.payload.result);
      });
    }
    case PortfolioMonitoringActionType.PM_DASHBOARD_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.portfolioMonitoringDashboard,
          action.payload.result
        );
      });
    }
    case PortfolioMonitoringActionType.PM_CONNECTORS_STATUS_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.portfolioMonitoringCompaniesConnectorStatus,
          action.payload.result
        );
      });
    }
    case PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANY_INVITE_URL_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(
          newState.portfolioCompanyInviteUrl,
          action.payload.result
        );
      });
    }
    case PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANIES_STATE_CHANGED: {
      return produce(appState, (newState) => {
        Object.assign(newState.portfolioCompanies, action.payload.result);
      });
    }
    default: {
      return appState;
    }
  }
};
