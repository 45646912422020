import React from 'react';
import { SyndicationMatchProfile2 } from '@hum/icm-app/src/state';
import {
  DescriptionList,
  DownloadFinancialsBar,
  Spinner,
  Text,
  TextType,
} from '@hum/ui-library';
import { useApiQuery, getPrivateProfile } from '@hum/api';

type CompanyOverviewProps = {
  matchData: SyndicationMatchProfile2;
  isDownloadingFile: boolean;
  showHumEnhancedFilesInvestor: boolean;
  goToDataRoomTab: () => void;
  clickDownloadButton: () => void;
};

export const CompanyOverviewV2 = ({
  matchData,
  goToDataRoomTab,
  clickDownloadButton,
  isDownloadingFile,
  showHumEnhancedFilesInvestor,
}: CompanyOverviewProps) => {
  const { data, isLoading } = useApiQuery(getPrivateProfile, {
    params: { companyId: matchData?.company?.id },
  });

  if (!data && isLoading) return <Spinner fullScreen />;
  if (!data) return null;

  return (
    <div
      className="w-full max-w-4xl mx-auto py-10 px-8"
      data-testid="syndication:investor-live-deals-overview-content"
    >
      <Text type={TextType.DISPLAY_S}>{data.hero.privateHeadline}</Text>
      <Text type={TextType.BODY_M} className="mt-4" subdued>
        {data.hero.privateDescription}
      </Text>
      {showHumEnhancedFilesInvestor && (
        <section className="my-10">
          <Text type={TextType.HEADING_L} className="my-5">
            Company Financials
          </Text>
          <Text type={TextType.BODY_M} subdued>
            Downloadable Excel file containing monthly company financials up to
            the most recent complete month obtained. Data sources can be found
            within the Excel file. To view more documents shared by this
            company, explore the{' '}
            <a
              onClick={() => goToDataRoomTab()}
              className="ui-link text-bodyMSize"
            >
              Data Room
            </a>
            .
          </Text>
          <DownloadFinancialsBar
            testId="syndication:investor-live-deals-overview-content:download-financials-bar"
            onClickDownload={clickDownloadButton}
            isDownloadingFile={isDownloadingFile}
          />
        </section>
      )}
      <section className="my-10">
        <Text type={TextType.HEADING_L} className="my-5">
          Contact info
        </Text>
        <DescriptionList>
          <DescriptionList.Item
            label="Primary contact"
            description={
              `${matchData?.company?.contact?.firstName || '–'} ${
                matchData?.company?.contact?.lastName || '–'
              }` || '–'
            }
          />
          <DescriptionList.Item
            label="Role"
            description={matchData?.company?.contact?.position || '–'}
          />
          <DescriptionList.Item
            label="Email"
            forceCapitalization={false}
            description={matchData?.company?.contact?.email || '–'}
          />
        </DescriptionList>
      </section>
      <section className="my-10">
        <Text type={TextType.HEADING_L} className="my-5">
          General
        </Text>
        <DescriptionList>
          {data?.hero.callouts &&
            Object.values(data.hero.callouts).map(({ id, title, value }) => (
              <DescriptionList.Item
                key={id}
                label={title}
                description={value}
              />
            ))}
        </DescriptionList>
      </section>
      {Object.keys(data?.financialHighlights?.highlights || {}).length > 0 && (
        <section className="my-10">
          <Text type={TextType.HEADING_L} className="my-5">
            Financial Highlights
          </Text>
          <DescriptionList>
            {data?.financialHighlights &&
              Object.values(
                data.financialHighlights.highlights
              ).map(({ id, title, value }) => (
                <DescriptionList.Item
                  key={id}
                  label={title}
                  description={value}
                />
              ))}
          </DescriptionList>
        </section>
      )}
    </div>
  );
};
